import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import TriangleIcon from "../icons/PlayIcon";
import { useWindowDimensions } from "../../utils/useWindowDimensions";

export const PlayImage = () => {
  const { width } = useWindowDimensions();

  const isDesktopMediumSize = Number(width) <= 1800;

  const TriangleIconWidth = isDesktopMediumSize ? 58 : 73;
  const TriangleIconHeight = isDesktopMediumSize ? 58 : 73;

  return (
    <Wrapper>
      <IconWrapper>
        <TriangleIcon width={TriangleIconWidth} height={TriangleIconHeight} />
      </IconWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 140px;
  background-color: #ffffff;

  @media (max-width: 1800px) {
    width: 112px;
    height: 112px;
  }
`;

const IconWrapper = styled(Box)`
  margin-top: 5%;
  margin-left: 12%;
`;
