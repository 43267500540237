import React, { forwardRef } from "react";
import styled from "@emotion/styled";

type ButtonProps = {
  children: React.ReactNode;
  variant?: "primary" | "secondary";
  onClick?: () => void;
  disabled?: boolean;
};

function Button(
  { children, onClick, variant = "primary", disabled, ...props }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <ButtonWrapper
      onClick={onClick}
      {...props}
      ref={ref}
      variant={variant}
      disabled={disabled}
    >
      {children}
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.button<{ variant: string; disabled?: boolean }>`
  appearance: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  border-radius: 100px;
  background: ${(props) =>
    props.variant === "primary" ? "#1472ff" : "#EEEFF0"};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: ${(props) => (props.variant === "primary" ? "white" : "black")};
  padding: 14px 24px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

export default forwardRef(Button);
