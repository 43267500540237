import React from "react";
import Row from "../components/Row";
import AuthInput from "../components/AuthInput";
import Button from "../components/Button";
import styled from "@emotion/styled";

type ForgotPasswordFormProps = {
  values: {
    email: string;
  };
  errors: {
    email?: string;
  };
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  goBack: () => void;
};

function ForgotPasswordForm({
  values,
  errors,
  handleChange,
  handleSubmit,
  goBack,
}: ForgotPasswordFormProps) {
  return (
    <FormContainer>
      <Form>
        <Row>
          <FormTitle>Forgot your password?</FormTitle>
        </Row>
        <Row>
          <Description>
            No worries! Enter the email address you used when you joined and
            we’ll send you a link to reset your password.
          </Description>
        </Row>
        <Row>
          <AuthInput
            type="email"
            name="email"
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
          />
        </Row>
        <ButtonContainer>
          <Button onClick={() => goBack()} variant="secondary">
            Back
          </Button>
          <BigButton onClick={handleSubmit}>Continue</BigButton>
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const FormTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 100%;
  justify-content: center;
  gap: 20px;
`;

const Description = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin: 0 0 30px 0;
  color: #000000;
`;

const BigButton = styled(Button)`
  flex: 2;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 25px;
`;

export default ForgotPasswordForm;
