import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";

type PmsFormProps = {
  error?: string;
  setError: (error: string) => void;
  onClose: () => void;
  onContinue: () => void;
};

function PmsForm({ error, setError, onClose, onContinue }: PmsFormProps) {
  const [pmsList, setPmsList] = useState<string[]>([]);
  const [selectedPMS, setSelectedPMS] = useState<string | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    axios.get("/api/pms/names").then((res) => {
      setPmsList(res.data);
    });
  }, []);

  const getLink = () => {
    axios
      .post("/api/pms/link-account", { pms_name: selectedPMS })
      .then((res) => {
        window.location.replace(res.data.redirectUrl || "");
      })
      .catch((err) => {
        setSelectedPMS(null);
        setError(err.response.data.error);
      });
  };

  return (
    <FormWrapper>
      <InputWrapper>
        <Label>Select PMS</Label>
        <DropdownWrapper>
          <DropdownField onClick={() => setDropdownOpen(true)}>
            {selectedPMS || "Select PMS"}
          </DropdownField>
          <Dropdown options={dropdownOpen}>
            {pmsList.map((option) => (
              <DropdownItem
                key={option}
                onClick={() => {
                  setSelectedPMS(option);
                  setDropdownOpen(false);
                }}
              >
                {option}
              </DropdownItem>
            ))}
          </Dropdown>
        </DropdownWrapper>
        <FieldError error={error}>{error}</FieldError>
      </InputWrapper>
      <ButtonContainer>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary disabled={!selectedPMS} onClick={getLink}>
          Continue
        </Button>
      </ButtonContainer>
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  justify-content: space-between;
  height: 300px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldError = styled.div<{ error?: string }>`
  display: ${(props) => (props?.error ? "block" : "none")};
  color: #ff8b8b;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const Dropdown = styled.div<{ options: boolean }>`
  position: absolute;
  display: ${(props) => (props.options ? "block" : "none")};
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #a8a6a6;
  border-radius: 6px;
  z-index: 1;
  overflow: auto;
  max-height: 200px;
`;
const DropdownItem = styled.div`
  padding: 5px 10px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const DropdownField = styled.div`
  width: 100%;
  border-radius: 6px;
  color: #a8a6a6;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
`;

const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 30px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;

export default PmsForm;
