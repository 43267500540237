import React from "react";
import styled from "@emotion/styled";

type WifiBlockType = {
    wifi_network?: string;
    wifi_password?: string;
    onNetworkChange: (str: string) => void;
    onPasswordChange: (str: string) => void;
};

function WifiBlock({
    wifi_network,
    wifi_password,
    onNetworkChange,
    onPasswordChange,
}: WifiBlockType) {
    return (
        <div>
            <Title>WIFI</Title>
            <Row>
                <TextFieldStyled
                    placeholder="Network"
                    value={wifi_network || ""}
                    onChange={(e) => onNetworkChange(e.target.value)}
                />
                <TextFieldStyled
                    placeholder="Password"
                    value={wifi_password || ""}
                    onChange={(e) => onPasswordChange(e.target.value)}
                />
            </Row>
        </div>
    );
}

export default WifiBlock;

const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
`;

const TextFieldStyled = styled.input`
    width: 100%;
    border-radius: 10px;
    color: black;
    border: 1px solid #a8a6a6;
    padding: 2px 10px;
    font-size: 14px;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    margin-bottom: 15px;

    &::placeholder {
        color: #a8a6a6;
    }
`;
