import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { RecommendationCategoryType, RecommendationType } from "../../types";
import { AddOutlined, DeleteOutlined } from "@mui/icons-material";
import { BasicModal } from "../../components/BasicModal";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

type RecommendationsProps = {
  recommendations?: RecommendationType[];
  onDeleteRecommendation: (rec: RecommendationType) => void;
  onAddRecommendation: (rec: RecommendationType) => void;
  onEditRecommendation: (rec: RecommendationType) => void;
};

const ValidationSchema = yup.object().shape({
  id: yup.string(),

  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name cannot exceed 50 characters"),

  category: yup.string().required("Category is required"),

  distance: yup
    .number()
    .typeError("Distance must be a number")
    .required("Distance is required")
    .positive("Distance must be a positive number")
    .test(
      "is-decimal",
      "Distance must be a number with up to 2 decimal places",
      (value) => !!(value + "").match(/^\d+(\.\d{1,2})?$/),
    ),
});

const Recommendations = ({
  recommendations,
  onDeleteRecommendation,
  onAddRecommendation,
  onEditRecommendation,
}: RecommendationsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState<RecommendationCategoryType[]>(
    [],
  );
  const [editMode, setEditMode] = useState(false);

  const { values, errors, handleChange, setValues, resetForm, submitForm } =
    useFormik({
      initialValues: {
        id: "",
        name: "",
        category: "",
        distance: "",
      },
      validationSchema: ValidationSchema,
      validateOnChange: false,
      onSubmit: (values) => {
        setIsOpen(false);
        if (editMode) {
          onEditRecommendation({
            id: values.id,
            name: values.name,
            distance: values.distance,
            category: categories.find((cat) => cat.id === values.category),
          });
          setEditMode(false);
        } else {
          onAddRecommendation({
            name: values.name,
            distance: values.distance,
            category: categories.find((cat) => cat.id === values.category),
          });
        }
        resetForm();
      },
    });

  useEffect(() => {
    axios.get("/api/recommendation-categories").then((response) => {
      setCategories(response.data);
    });
  }, []);

  const onCancel = () => {
    setIsOpen(false);
    setEditMode(false);
    resetForm();
  };

  const onEdit = (rec: RecommendationType) => {
    setEditMode(true);
    setValues({
      id: rec.id || "",
      category: rec.category?.id || "",
      distance: rec.distance,
      name: rec.name,
    });
    setIsOpen(true);
  };

  const renderRow = (recommendation: RecommendationType, key: number) => {
    return (
      <Row onClick={() => onEdit(recommendation)} key={key}>
        <Item>{recommendation.name}</Item>
        <Item>{recommendation.category?.name}</Item>
        <Item>{`${recommendation.distance} Miles`}</Item>
        <Icon
          onClick={(e) => {
            e.stopPropagation();
            onDeleteRecommendation(recommendation);
          }}
          className="icon"
        >
          <DeleteOutlined fontSize={"small"} />
        </Icon>
      </Row>
    );
  };

  return (
    <>
      <Container>
        <Title>
          Recommendations{" "}
          <Add onClick={() => setIsOpen(true)}>
            <AddOutlined fontSize={"inherit"} />
          </Add>
        </Title>
        <Table>
          <Header>
            <Item>Name</Item>
            <Item>Category</Item>
            <Item>Distance</Item>
          </Header>
          {recommendations?.map((rec, i) => renderRow(rec, i))}
        </Table>
      </Container>
      <BasicModal open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContainer>
          <ModalTitle>Insert recommendations</ModalTitle>
          <InputContainer>
            <InputWrapper>
              <Label>Name</Label>
              <TextFieldStyled
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <FieldError error={errors.name}>{errors.name}</FieldError>
            </InputWrapper>
            <InputWrapper>
              <Label>Category</Label>
              <Select
                value={values.category}
                onChange={handleChange}
                name="category"
              >
                {categories.map((category) => (
                  <MenuStyledItem
                    key={category.id}
                    value={category.id}
                    active={category.id === values.category}
                  >
                    {category.name}
                  </MenuStyledItem>
                ))}
              </Select>
              <FieldError error={errors.category}>{errors.category}</FieldError>
            </InputWrapper>
            <InputWrapper>
              <Label>Distance</Label>
              <TextFieldStyled
                placeholder="Distance"
                name="distance"
                value={values.distance}
                onChange={handleChange}
              />
              <FieldError error={errors.distance}>{errors.distance}</FieldError>
            </InputWrapper>
          </InputContainer>
          <ButtonContainer>
            <Button onClick={() => onCancel()}>Cancel </Button>
            <Button onClick={submitForm} primary>
              {editMode ? "Submit" : "Add"}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </BasicModal>
    </>
  );
};

export default Recommendations;

const Container = styled.div``;

const Title = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #a8a6a6;
  border-bottom: 1px solid black;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 10px;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #a8a6a6;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: #7ab5fa;
  }

  &:hover .icon {
    color: #f35b5b;
  }
`;

const Item = styled.div`
  flex: 1;
`;

const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
`;

const Add = styled.div`
  cursor: pointer;
  margin-left: auto;
  font-size: 28px;
  line-height: 28px;
  color: #0076ff;
`;
const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  padding: 0 20px;
  width: 650px;
`;

const ModalTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 30px;
  margin-right: auto;
  margin-bottom: 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 15px;
`;
const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const MenuStyledItem = styled(MenuItem)<{ active?: boolean }>`
  &:after {
    content: " ";
    display: ${(props) => (props?.active ? "block" : "none")};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0d6efd;
    margin-left: auto;
  }
`;

const FieldError = styled.div<{ error?: string }>`
  display: ${(props) => (props?.error ? "block" : "none")};
  color: #ff8b8b;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
`;
