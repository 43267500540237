import React from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import Settings from "@mui/icons-material/Settings";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import styled from "@emotion/styled";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";

export const MainMenu = () => {
  const logOut = () => {
    Cookies.remove("auth_token");
  };

  return (
    <MenuList>
      <MenuItemStyled active>
        <LinkStyled href="/properties">
          <ListItemIcon>
            <HomeIcon fontSize="small" sx={{ color: "#1982FC" }} />
          </ListItemIcon>
          <span>Properties</span>
        </LinkStyled>
      </MenuItemStyled>
      <MenuItemStyled>
        <LinkStyled href="/settings">
          <ListItemIcon>
            <Settings fontSize="small" sx={{ color: "000" }} />
          </ListItemIcon>
          <span>Settings</span>
        </LinkStyled>
      </MenuItemStyled>
      <MenuItemStyled>
        <LinkStyled href="/guide">
          <ListItemIcon>
            <CollectionsBookmarkIcon fontSize="small" sx={{ color: "000" }} />
          </ListItemIcon>
          <span>Guide</span>
        </LinkStyled>
      </MenuItemStyled>
      <MenuItemStyled>
        <LinkStyled href="/login" onClick={() => logOut()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" sx={{ color: "000" }} />
          </ListItemIcon>
          <span>Log Out</span>
        </LinkStyled>
      </MenuItemStyled>
    </MenuList>
  );
};

const LinkStyled = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: #000;
`;

const MenuItemStyled = styled(MenuItem)<{ active?: boolean }>`
  height: 40px;
  display: flex;
  //align-items: center;
  //justify-content: center;
  border-radius: 3px !important;
  border-left: ${({ active }) =>
    active ? "1px solid #1982FC" : "none"} !important;
  padding: 8px 0 8px 12px;
  background: ${({ active }) =>
    active ? "#E8F3FE" : "transparent"} !important;
  color: ${(p) => (p.active ? "#1982FC" : "black")} !important;
  margin-bottom: 4px !important;
  transition: 0.2s linear;
`;
