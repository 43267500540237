import React from "react";
import styled from "@emotion/styled";

function Row({ children }: { children: React.ReactNode }) {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  //flex: 1;
  width: 100%;
  gap: 20px;
`;

export default Row;
