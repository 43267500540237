import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { WelcomeScreenTitle } from "../../components/WelcomeScreenTitle";
import { WelcomeScreenQrCode } from "../../components/WelcomeScreenQrCode";
import axios from "axios";
import { IResponseWelcomeScreenData } from "../../types";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/TVScreenLoader/Loader";
import { FocusedElementType } from "../../types/enum";
import { WelcomeScreenLeftMenuInfo } from "../../components/WelcomeScreenLeftInfoMenu";
import VideoPlayer from "../../components/WelcomeScreenVideoPlayer";
import CustomSlider from "../../components/CustomSlider/CustomSlider";
import { WeatherSection } from "../../components/WeatherSection";
import { WelcomeScreenRightInfoMenu } from "../../components/WelcomeScreenRightInfoMenu";

const WelcomeScreen = () => {
  const { tokenId } = useParams<{ tokenId: string }>();
  const { propertyId } = useParams<{ propertyId: string }>();

  const [welcomeScreenData, setWelcomeScreenData] =
    useState<IResponseWelcomeScreenData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPlayingSlider, setIsPlayingSlider] = useState(true);

  const videoPlayerRef = useRef<HTMLIFrameElement>(null);

  const {
    background,
    events,
    recommendations,
    guest,
    location,
    gallery,
    use_video: useVideo,
    video_url: videoUrl,
    qr_code,
    wifi_network,
    wifi_password,
    contact_phone,
    contact_email,
    welcome_greeting,
    welcome_message,
    use_white_font,
  } = welcomeScreenData ?? {};

  const [focusedComponent, setFocusedComponent] = useState<string | null>(
    !useVideo ? FocusedElementType.SLIDER : FocusedElementType.VIDEO_PLAYER,
  );

  const { timezone, coordinates } = location ?? {};
  const { date_checkin, date_checkout } = guest ?? {};
  const { original } = background?.url ?? {};
  const gallerySlideUrls: string[] =
    gallery?.map((slide) => slide.url.original) ?? [];

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowRight":
        setFocusedComponent(
          useVideo
            ? FocusedElementType.VIDEO_PLAYER
            : FocusedElementType.SLIDER,
        );
        break;

      case "ArrowLeft":
        setFocusedComponent("");
        break;

      case "Play":
        if (
          focusedComponent === FocusedElementType.VIDEO_PLAYER &&
          videoPlayerRef.current
        ) {
          videoPlayerRef.current?.contentWindow?.postMessage(
            JSON.stringify({
              event: "command",
              func: "playVideo",
              args: [],
            }),
            "*",
          );
        } else if (focusedComponent === FocusedElementType.SLIDER) {
          setIsPlayingSlider(true);
        }
        break;

      case "Pause":
        if (
          focusedComponent === FocusedElementType.VIDEO_PLAYER &&
          videoPlayerRef.current
        ) {
          videoPlayerRef.current.contentWindow?.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*",
          );
        } else if (focusedComponent === FocusedElementType.SLIDER) {
          setIsPlayingSlider(false);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedComponent]);

  useEffect(() => {
    const getWelcomeScreenData = () => {
      setIsLoading(true);

      axios
        .get("/api/screen/data", {
          headers: {
            "Screen-Token": tokenId,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setWelcomeScreenData(response.data);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setIsLoading(false);
        });
    };

    const getWelcomeScreenPreviewData = () => {
      setIsLoading(true);

      axios
        .get(`/api/preview/${propertyId}`, {
          headers: {
            "Screen-Token": tokenId,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setWelcomeScreenData(response.data);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setIsLoading(false);
        });
    };

    if (tokenId) {
      getWelcomeScreenData();

      return;
    }

    if (propertyId) {
      getWelcomeScreenPreviewData();
    }
  }, []);

  if (isLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  return (
    <BackgroundWrapper url={original ?? ""}>
      <Wrapper>
        <Row flex={1} justify="space-evenly">
          <Logo>
            <img src="/logo.png" alt="Logo" />
          </Logo>
          <WelcomeScreenTitle
            fromDate={date_checkin}
            toDate={date_checkout}
            timezone={timezone}
            useWhiteFont={use_white_font}
          />

          <WelcomeScreenQrCode
            qrCodeUrl={qr_code ?? ""}
            useWhiteFont={use_white_font}
          />
        </Row>
        <Row flex={4} gap={20}>
          <Column flex={1}>
            <WelcomeScreenLeftMenuInfo
              wifi_network={wifi_network}
              wifi_password={wifi_password}
              contact_email={contact_email}
              contact_phone={contact_phone}
              welcome_greeting={welcome_greeting}
              welcome_message={welcome_message}
              guest={guest}
            />
          </Column>
          <Column flex={2} gap={20}>
            <Row flex={5}>
              {useVideo ? (
                <VideoPlayer
                  ref={videoPlayerRef}
                  src={videoUrl}
                  isFocused={
                    focusedComponent === FocusedElementType.VIDEO_PLAYER
                  }
                />
              ) : (
                <CustomSlider
                  images={gallerySlideUrls}
                  isFocused={focusedComponent === FocusedElementType.SLIDER}
                  isPlayingSlider={isPlayingSlider}
                  setIsPlayingSlider={setIsPlayingSlider}
                />
              )}
            </Row>
            <Row flex={2}>
              <WeatherSection coordinates={coordinates} timezone={timezone} />
            </Row>
          </Column>
          <Column flex={1}>
            <WelcomeScreenRightInfoMenu
              events={events || []}
              recommendations={recommendations || []}
            />
          </Column>
        </Row>
      </Wrapper>
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div<{ url: string }>`
  label: bg-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ url }) => `url(${url})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 30px 30px 17px 30px;

  @media (min-width: 1280px) and (max-width: 1919px) {
    padding: 60px 60px 33px 60px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    padding: 90px 90px 50px 90px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    padding: 180px 180px 100px 180px;
  }

  @media (min-width: 7680px) {
    padding: 360px 360px 200px 360px;
  }
`;

const Wrapper = styled.div`
  label: wrapper;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  font-size: 10px;
  height: 100%;
  flex: 1;
`;

const Row = styled.div<{
  flex: number;
  apart?: boolean;
  gap?: number;
  justify?: string;
  align?: string;
}>`
  label: row;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({ justify }) => justify || "none"};
  align-items: ${({ align }) => align || "none"};
  gap: ${({ gap }) => gap || 0}px;
  flex: ${({ flex }) => flex} 1 0;
`;

const Logo = styled.div`
  flex: 1;
  padding-left: 20px;

  img {
    margin: 0 auto;
    width: 100px;
    object-fit: contain;
  }
`;

const Column = styled.div<{ flex: number; gap?: number }>`
  label: column;
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex} 1 0;
  gap: ${({ gap }) => gap || 0}px;
`;

export default WelcomeScreen;
