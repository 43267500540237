import { FC, useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { WeatherSectionProps } from "./types";
import { WeatherData } from "./interfaces";
import { BASE_WEATHER_URL } from "./constants";
import { isLocalMidnight } from "../../utils/isLocalMidnight";
import moment from "moment";

export const WeatherSection: FC<WeatherSectionProps> = ({
  coordinates,
  forecastDays = 5,
  timezone,
}) => {
  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get<WeatherData>(BASE_WEATHER_URL, {
          params: {
            key: process.env.REACT_APP_WEATHER_API_KEY,
            q: coordinates,
            days: forecastDays,
          },
        });

        setWeatherData(response.data);
      } catch (err) {
        setError("Error fetching weather data");
      } finally {
        setLoading(false);
      }
    };

    fetchWeatherData();

    // this needed to update weather date ones at 12:00 am
    const intervalId = setInterval(() => {
      if (!timezone) return;

      if (isLocalMidnight(timezone)) {
        fetchWeatherData();
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [timezone, coordinates, forecastDays]);

  if (loading)
    return (
      <WeatherWrapper>
        {" "}
        <Typography align="center" color="white">
          Loading...
        </Typography>{" "}
      </WeatherWrapper>
    );
  if (error && !weatherData)
    return (
      <WeatherWrapper>
        <Typography color="red" align="center">
          {error}
        </Typography>{" "}
      </WeatherWrapper>
    );

  return (
    <WeatherWrapper>
      <ForecastContainer>
        {weatherData?.forecast.forecastday.map((forecastDay) => {
          const { date, day } = forecastDay ?? {};
          const { condition, avgtemp_f } = day ?? {};

          return (
            <ForecastDayStyled key={forecastDay?.date}>
              <TitleWrapper>
                <StyledLocationName color="white">
                  {weatherData?.location.name}
                </StyledLocationName>

                <StyledDate color="white">
                  {moment(date).format("ddd, MMM DD")}
                </StyledDate>
              </TitleWrapper>

              <StyledAverageTemperature color="white">
                {avgtemp_f}°
              </StyledAverageTemperature>

              <StyledImage src={condition?.icon} alt="Weather icon" />

              <StyledCondition>{condition?.text}</StyledCondition>
            </ForecastDayStyled>
          );
        })}
      </ForecastContainer>
    </WeatherWrapper>
  );
};

const WeatherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #4a4949;
  box-shadow: 0 4px 4px 0 #00000040;
  padding: 10px;
  border-radius: 10px;
  opacity: 90%;
  align-items: center;
`;

const ForecastContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  overflow-x: auto;
`;

const ForecastDayStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLocationName = styled.p`
  font-size: 12px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: white;
  margin: 0;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 17px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 22px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 39px; /* Adjusted using square scaling */
  }

  @media (min-width: 7680px) {
    font-size: 78px; /* Adjusted using square scaling */
  }
`;

const StyledDate = styled.p`
  font-size: 10px;
  line-height: 14px;
  font-family: "Inter", sans-serif;
  color: white;
  margin: 0;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 13px;
    line-height: 16px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 17px;
    line-height: 20px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 31px; /* Adjusted using square scaling */
    line-height: 37px;
  }

  @media (min-width: 7680px) {
    font-size: 62px; /* Adjusted using square scaling */
    line-height: 74px;
  }
`;

const StyledAverageTemperature = styled.p`
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: white;
  margin: 0;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 22px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 28px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 50px; /* Adjusted using square scaling */
  }

  @media (min-width: 7680px) {
    font-size: 100px; /* Adjusted using square scaling */
  }
`;

const StyledCondition = styled.p`
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: white;
  font-weight: 500;
  margin: 0;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 16px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 20px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 36px; /* Adjusted using square scaling */
  }

  @media (min-width: 7680px) {
    font-size: 72px; /* Adjusted using square scaling */
  }
`;

const StyledImage = styled.img`
  width: 60px;
  height: 60px;

  @media (min-width: 1281px) and (max-width: 1919px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    width: 130px;
    height: 130px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    width: 200px; /* Adjusted using square scaling */
    height: 200px;
  }

  @media (min-width: 7680px) {
    width: 400px; /* Adjusted using square scaling */
    height: 400px;
  }
`;
