import React from "react";
import styled from "@emotion/styled";
import { MainMenu } from "./MainMenu";
import Link from "@mui/material/Link";

export const SideBar = () => {
  return (
    <SideBarWrap>
      <LogoWrap>
        <img src={"/logo-wide.png"} />
        {/*<TextWrap>ZoneSage</TextWrap>*/}
      </LogoWrap>
      <MainMenu />
    </SideBarWrap>
  );
};

const SideBarWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 100%;
  background: white;
  gap: 50px;
  position: fixed;
  width: 230px;
  z-index: 2;
`;

const TextWrap = styled.div`
  font-size: 24px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
`;

const LogoWrap = styled(Link)`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none !important;
  color: #000 !important;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &:hover {
    cursor: pointer;
  }
`;
