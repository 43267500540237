import styled from "@emotion/styled";
import { FC } from "react";
import { IEvent } from "../../../../types";
import { formatDateAndTime } from "../../../../utils/formatDateAndTime";

type EventListProps = {
  events: IEvent[];
};

export const EventsList: FC<EventListProps> = ({ events }) => {
  if (!events.length) {
    return (
      <Wrapper isErrorWrap>
        <NoEventsText>No events yet</NoEventsText>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>Upcoming Events</Title>

      <EventsWrapper>
        {events.map(({ id, name, datetime }) => {
          const { date, time } = formatDateAndTime(datetime);

          return (
            <InfoBlock key={id}>
              <InfoBlockTitle>{name}</InfoBlockTitle>

              <StyledBox>
                <InfoBlockDate>{date}</InfoBlockDate>

                <InfoBlockTime>{time}</InfoBlockTime>
              </StyledBox>
            </InfoBlock>
          );
        })}
      </EventsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  isErrorWrap?: boolean;
  isExpanded?: boolean;
  isSqueezed?: boolean;
}>`
  position: relative;
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  background: rgba(74, 73, 73, 0.75);
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 8px;
  gap: 20px;
  padding: 20px 17px 17px;
  box-sizing: border-box;
  ${({ isErrorWrap }) => !!isErrorWrap && "justify-content: center;"};

  @media (min-width: 1281px) {
    padding: 30px 30px 20px;
  }
`;

const EventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  gap: 10px;
  width: 100%;

  @media (min-width: 1920px) {
    gap: 20px;
  }
`;

const Title = styled.p`
  font-size: 14px; /* Default HD value */
  color: white;
  font-weight: 600;
  font-family: Inter, sans-serif;
  margin: 0;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 14px; /* HD value */
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 18px; /* FullHD value */
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 31.86px; /* 2K value: 18 * 1.77 */
  }

  @media (min-width: 7680px) {
    font-size: 71.68px; /* 4K value: 31.86 * 2.25 */
  }

  @media (min-width: 15360px) {
    font-size: 286.72px; /* 8K value: 71.68 * 4 */
  }
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 10px 16px;
  background: #ffffff;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 1281px) {
    padding: 13px 25px;
    border-radius: 20px;
  }
`;

const InfoBlockTitle = styled.p`
  color: #1982fc;
  font-size: 11px; /* Default HD value */
  line-height: 13px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  margin: 0;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 14px; /* HD value */
    line-height: 11px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 20px; /* FullHD value */
    line-height: 24px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 35.4px; /* 2K value: 20 * 1.77 */
    line-height: 42.48px;
  }

  @media (min-width: 7680px) {
    font-size: 79.65px; /* 4K value: 35.4 * 2.25 */
    line-height: 95.46px;
  }

  @media (min-width: 15360px) {
    font-size: 318.6px; /* 8K value: 79.65 * 4 */
    line-height: 381.84px;
  }
`;

const InfoBlockDate = styled.p`
  font-size: 10px; /* Default HD value */
  line-height: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin: 0;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 12px; /* HD value */
    line-height: 16px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 16px; /* FullHD value */
    line-height: 19px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 28.32px; /* 2K value: 16 * 1.77 */
    line-height: 33.63px;
  }

  @media (min-width: 7680px) {
    font-size: 63.72px; /* 4K value: 28.32 * 2.25 */
    line-height: 75.66px;
  }

  @media (min-width: 15360px) {
    font-size: 254.88px; /* 8K value: 63.72 * 4 */
    line-height: 302.64px;
  }
`;

const InfoBlockTime = styled.p`
  font-size: 10px; /* Default HD value */
  line-height: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin: 0;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 12px; /* HD value */
    line-height: 16px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 16px; /* FullHD value */
    line-height: 19px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 28.32px; /* 2K value: 16 * 1.77 */
    line-height: 33.63px;
  }

  @media (min-width: 7680px) {
    font-size: 63.72px; /* 4K value: 28.32 * 2.25 */
    line-height: 75.66px;
  }

  @media (min-width: 15360px) {
    font-size: 254.88px; /* 8K value: 63.72 * 4 */
    line-height: 302.64px;
  }
`;

const NoEventsText = styled.p`
  font-size: 10.5px; /* Default HD value */
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin: 0;
  color: white;
  text-align: center;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 10.5px; /* HD value */
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 24px; /* FullHD value */
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 42.48px; /* 2K value: 24 * 1.77 */
  }

  @media (min-width: 7680px) {
    font-size: 95.58px; /* 4K value: 42.48 * 2.25 */
  }

  @media (min-width: 15360px) {
    font-size: 382.32px; /* 8K value: 95.58 * 4 */
  }
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
