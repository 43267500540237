import styled from "@emotion/styled";
import React, { useState } from "react";
import { EventType } from "../../types";
import moment from "moment";
import { AddOutlined, DeleteOutlined } from "@mui/icons-material";
import { useFormik } from "formik";
import { BasicModal } from "../../components/BasicModal";
import * as yup from "yup";

type UpcomingEventsProps = {
  events?: EventType[];
  onDeleteEvent: (event: EventType) => void;
  onEditEvent: (event: EventType) => void;
  onAddEvent: (event: EventType) => void;
};

const ValidationSchema = yup.object().shape({
  id: yup.string(),

  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name cannot exceed 50 characters"),

  date: yup.string().required("Date is required"),

  time: yup.string().required("Time is required"),
});

const UpcomingEvents = ({
  events,
  onDeleteEvent,
  onEditEvent,
  onAddEvent,
}: UpcomingEventsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { values, errors, handleChange, setValues, resetForm, submitForm } =
    useFormik({
      initialValues: {
        id: "",
        date: "",
        time: "",
        name: "",
      },
      validationSchema: ValidationSchema,
      validateOnChange: false,
      onSubmit: (values) => {
        setIsOpen(false);
        if (editMode) {
          onEditEvent({
            id: values.id,
            name: values.name,
            datetime: `${values.date} ${values.time}`,
          });
          setEditMode(false);
        } else {
          onAddEvent({
            name: values.name,
            datetime: `${values.date} ${values.time}`,
          });
        }
        resetForm();
      },
    });

  const onCancel = () => {
    setIsOpen(false);
    setEditMode(false);
    resetForm();
  };

  const onEdit = (event: EventType) => {
    setEditMode(true);
    setValues({
      id: event.id || "",
      date: moment(event.datetime).format("YYYY-MM-DD") || "",
      time: moment(event.datetime).format("HH:mm"),
      name: event.name,
    });
    setIsOpen(true);
  };

  const renderRow = (event: EventType, key: number) => {
    const date = moment(event.datetime);
    return (
      <Row onClick={() => onEdit(event)} key={key}>
        <Item>{event.name}</Item>
        <Item>{date.format("L")}</Item>
        <Item>{date.format("LT")}</Item>
        <Icon
          onClick={(e) => {
            e.stopPropagation();
            onDeleteEvent(event);
          }}
          className="icon"
        >
          <DeleteOutlined fontSize={"small"} />
        </Icon>
      </Row>
    );
  };

  return (
    <>
      <Container>
        <Title>
          Upcoming Events
          <Add onClick={() => setIsOpen(true)}>
            <AddOutlined fontSize={"inherit"} />
          </Add>
        </Title>
        <Table>
          <Header>
            <Item>Events</Item>
            <Item>Date</Item>
            <Item>Time</Item>
          </Header>
          {events?.map((ev, i) => renderRow(ev, i))}
        </Table>
      </Container>
      <BasicModal open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContainer>
          <ModalTitle>Insert event</ModalTitle>
          <InputContainer>
            <InputWrapper>
              <Label>Name</Label>
              <TextFieldStyled
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <FieldError error={errors.name}>{errors.name}</FieldError>
            </InputWrapper>
            <DatetimeRow>
              <InputWrapper>
                <Label>Date</Label>
                <DateTimeInput
                  type="date"
                  placeholder="Enter date"
                  value={values.date}
                  onChange={handleChange}
                  min={moment().format("YYYY-MM-DD")}
                  name="date"
                />
                <FieldError error={errors.date}>{errors.date}</FieldError>
              </InputWrapper>
              <InputWrapper>
                <Label>Time</Label>
                <DateTimeInput
                  type="time"
                  placeholder="Enter time"
                  value={values.time}
                  onChange={handleChange}
                  min={
                    moment(values.date).isSame(moment(), "day")
                      ? moment().format("HH:mm")
                      : undefined
                  }
                  disabled={!values.date}
                  name="time"
                />
                <FieldError error={errors.time}>{errors.time}</FieldError>
              </InputWrapper>
            </DatetimeRow>
          </InputContainer>
          <ButtonContainer>
            <Button onClick={() => onCancel()}>Cancel </Button>
            <Button onClick={submitForm} primary>
              {editMode ? "Submit" : "Add"}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </BasicModal>
    </>
  );
};

export default UpcomingEvents;

const Container = styled.div``;

const Title = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #a8a6a6;
  border-bottom: 1px solid black;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 10px;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #a8a6a6;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: #7ab5fa;
  }

  &:hover .icon {
    color: #f35b5b;
  }
`;

const Item = styled.div`
  flex: 1;
`;

const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
`;

const Add = styled.div`
  cursor: pointer;
  margin-left: auto;
  font-size: 28px;
  line-height: 28px;
  color: #0076ff;
`;

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  padding: 0 20px;
  width: 650px;
`;

const ModalTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 30px;
  margin-right: auto;
  margin-bottom: 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DatetimeRow = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: space-evenly;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 15px;
`;
const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const FieldError = styled.div<{ error?: string }>`
  display: ${(props) => (props?.error ? "block" : "none")};
  color: #ff8b8b;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
`;

const DateTimeInput = styled.input`
  flex: 1;
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::-webkit-datetime-edit {
    flex: unset;
    padding-right: 15px;
  }

  &::-webkit-calendar-picker-indicator {
    box-sizing: content-box;
    width: 30px;
    height: 30px;
    filter: opacity(0.5);
    cursor: pointer;
    //text-align: right;
    background-position: right;
    flex: 1;
  }
`;
