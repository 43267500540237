import styled from "@emotion/styled";
import { FC } from "react";
import { WelcomeScreenLeftMenuInfoProps } from "./types";
import { WifiSection } from "./elements/WifiSection";
import { ContactsSection } from "./elements/ContactsSection";

export const WelcomeScreenLeftMenuInfo: FC<WelcomeScreenLeftMenuInfoProps> = ({
  welcome_message,
  welcome_greeting,
  wifi_network,
  wifi_password,
  contact_email,
  contact_phone,
  guest,
}) => (
  <Wrapper>
    <TitleTypography>
      {welcome_greeting
        ?.replace("$first", guest?.first_name || "John")
        .replace("$last", guest?.last_name || "Doe") || "N/A"}
    </TitleTypography>

    <WelcomeMessageWrapper>
      <StyledWelcomeMessage>
        {welcome_message?.slice(0, 255) ||
          "Welcome message currently is unavailable"}
      </StyledWelcomeMessage>
    </WelcomeMessageWrapper>

    <div className="contact-wrapper">
      <WifiSection wifi_password={wifi_password} wifi_network={wifi_network} />

      <ContactsSection
        contact_phone={contact_phone}
        contact_email={contact_email}
      />
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: rgba(74, 73, 73, 0.75);
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px 17px 17px;
  gap: 15px;

  & .contact-wrapper {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  @media (min-width: 1281px) and (max-width: 1919px) {
    padding: 40px 34px 34px;
    gap: 30px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    padding: 60px 51px 51px;
    gap: 40px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    padding: 80px 68px 68px;
    gap: 55px;
  }

  @media (min-width: 7680px) {
    padding: 160px 136px 136px;
    gap: 110px;
  }
`;

const TitleTypography = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 29px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  text-align: center;
  color: #ffffff;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media (min-width: 1920px) and (max-width: 2559px) {
    font-size: 36px;
    line-height: 44px;
  }

  @media (min-width: 2560px) and (max-width: 3839px) {
    font-size: 48px;
    line-height: 58px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 72px;
    line-height: 87px;
  }

  @media (min-width: 7680px) {
    font-size: 72px;
    line-height: 87px;
  }
`;

const StyledWelcomeMessage = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  text-align: left;
  color: #ffffff;

  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: 1920px) and (max-width: 2559px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: 2560px) and (max-width: 3839px) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 48px;
    line-height: 58px;
  }

  @media (min-width: 7680px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

const WelcomeMessageWrapper = styled.div`
  overflow: hidden;
`;
