import styled from "@emotion/styled";
import { FC } from "react";
import { WelcomeScreenRightInfoMenuProps } from "./types";
import { RecommendationsList } from "./elements/RecommendationsList";
import { EventsList } from "./elements/EventsList";

export const WelcomeScreenRightInfoMenu: FC<
  WelcomeScreenRightInfoMenuProps
> = ({ events, recommendations }) => {
  return (
    <Wrapper>
      <EventsList events={events} />

      <RecommendationsList recommendations={recommendations} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
`;
