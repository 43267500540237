const updateNestedState = (state: unknown, path: string, value: unknown) => {
  const keys = path.split(".");
  if (keys.length === 0) {
    throw new Error("Path cannot be empty");
  }

  const lastKey = keys.pop();
  if (!lastKey) {
    throw new Error("Invalid path");
  }

  // Initialize a copy of the state to avoid direct mutation
  //@ts-expect-error - to make the function flexible
  const newState = { ...state };

  // Use a reducer to get or create the nested object
  const nestedState = keys.reduce((obj, key) => {
    if (obj[key] === null || typeof obj[key] !== "object") {
      obj[key] = {};
    }
    return obj[key];
  }, newState);

  // Update the nested value
  nestedState[lastKey] = value;

  return newState;
};
export default updateNestedState;
