import React, { useRef } from "react";
import Row from "../components/Row";
import Column from "../components/Column";
import Button from "../components/Button";
import styled from "@emotion/styled";

type VerifyCodeFormProps = {
  values: {
    verificationCode: string;
  };
  error: {
    verificationCode?: string;
  };
  email: string;
  handleChange: (value: string) => void;
  handleSubmit: () => void;
};

function VerifyCodeForm({
  values,
  error,
  email,
  handleChange,
  handleSubmit,
}: VerifyCodeFormProps) {
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;

    if (value.length === 1 && index === 3) {
      buttonRef.current?.focus();
    }

    if (value.length === 1 && index < 3) {
      inputsRef.current[index + 1]?.focus();
    }

    // Collect all current values from inputs
    const updatedCode = inputsRef.current
      .map((input) => input?.value || "")
      .join("");

    handleChange(updatedCode);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  return (
    <FormContainer>
      <SignupForm>
        <Row>
          <FormTitle>Verify your email</FormTitle>
        </Row>
        <Row>
          <FormDescription>
            Please enter the 4 digit verification code that was sent to: <br />{" "}
            <p>{email}</p>
          </FormDescription>
        </Row>
        <Row>
          {[0, 1, 2, 3].map((_, index) => (
            <Column key={index}>
              <CodeInput
                name="3"
                type="text"
                placeholder=""
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength={1}
                ref={(el) => (inputsRef.current[index] = el)}
                value={values.verificationCode[index] || ""}
              />
            </Column>
          ))}
        </Row>
        <Row>
          {error.verificationCode && <Error>{error.verificationCode}</Error>}
        </Row>
        <ButtonContainer>
          <Button onClick={handleSubmit} ref={buttonRef}>
            Verify
          </Button>
        </ButtonContainer>
      </SignupForm>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const FormTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
`;

const FormDescription = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin: 0 0 75px 0;

  p {
    font-weight: 400;
    color: #585858;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-align: left;
    margin: 10px 0 0 0;
  }
`;

const SignupForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
  height: 100%;
  justify-content: center;
  gap: 20px;
`;

const CodeInput = styled.input`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 18px 24px;
  border: 1px solid #5858587f;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
`;

const Error = styled.span`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: red;
`;

export default VerifyCodeForm;
