import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

const PrivateRoute = (props: React.PropsWithChildren) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true); // Track loading state
  const [token, setTokenState] = useState<string | null>(
    Cookies.get("auth_token"),
  );
  const navigate = useNavigate();

  useEffect(() => {
    // Check for token in URL search params
    const urlParams = new URLSearchParams(location.search);
    const tokenInParams = urlParams.get('token');

    if (tokenInParams) {
      Cookies.set('auth_token', tokenInParams); // Set the token in cookies
      axios.defaults.headers.common['Authorization'] =
        `Bearer ${tokenInParams}`;
      setTokenState(tokenInParams); // Update state with new token
      urlParams.delete('token'); // Clean URL by removing token param
      window.history.replaceState({}, '', location.pathname); // Replace the URL without token param
    }

    setLoading(false); // Finalize loading status
  }, [location]);

  // Avoid rendering the protected route content until token processing is complete

  // Redirect to login if token is missing after loading
  useEffect(() => {
    if (!loading && !token) {
      navigate("/login");
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, [loading, token, navigate]);

  return <>{loading ? <div>Loading...</div> : props.children}</>; // Render the protected content
};

export default PrivateRoute;
