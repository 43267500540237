import React from "react";
import Row from "../components/Row";
import Column from "../components/Column";
import SocialButton from "../components/SocialButton";
import AuthInput from "../components/AuthInput";
import Button from "../components/Button";
import styled from "@emotion/styled";

type SignUpFormProps = {
  values: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  };
  errors: {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  googleRedirect?: string;
  facebookRedirect?: string;
};

function SignUpForm({
  values,
  errors,
  handleChange,
  handleSubmit,
  googleRedirect,
  facebookRedirect,
}: SignUpFormProps) {
  return (
    <FormContainer>
      <SignupForm>
        <Row>
          <FormTitle>Create your account</FormTitle>
        </Row>
        <Row>
          <Column>
            <SocialButton href={googleRedirect}>
              <SocialImage src="/google.svg" />
              <p>Connect with Google</p>
            </SocialButton>
            <SocialButton href={facebookRedirect}>
              <SocialImage src="/facebook.svg" />
              <p>Connect with Facebook</p>
            </SocialButton>
          </Column>
        </Row>
        <Divider>
          <p>OR</p>
        </Divider>
        <Row>
          <Column>
            <AuthInput
              type="text"
              name="firstName"
              placeholder="First name"
              value={values.firstName}
              onChange={handleChange}
              error={errors.firstName}
            />
          </Column>
          <Column>
            <AuthInput
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={values.lastName}
              onChange={handleChange}
              error={errors.lastName}
            />
          </Column>
        </Row>
        <Row>
          <AuthInput
            type="email"
            name="email"
            placeholder="Your email address"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
          />
        </Row>
        <Row>
          <AuthInput
            type="password"
            name="password"
            placeholder="Set Password"
            value={values.password}
            onChange={handleChange}
            error={errors.password}
          />
        </Row>
        <ButtonContainer>
          <Button onClick={handleSubmit}>Sign up</Button>
        </ButtonContainer>
      </SignupForm>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const FormTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.73px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
`;

const SignupForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  height: 100%;
  justify-content: center;
  gap: 20px;
  @media (min-width: 1439px) {
    margin-top: 50px;
  }
`;

const Divider = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  position: relative;
  color: #5c5c5c;

  p {
    position: relative;
    width: fit-content;
    padding: 0 10px;
    margin: 0 auto;
    background-color: white;
    z-index: 2;
  }

  &:before {
    content: "";
    width: 100%;
    border-bottom: solid 1px #eeeff0;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`;

const SocialImage = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
`;

export default SignUpForm;
