import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { WelcomeMessage } from "./WelcomeMessage";
import ManageTv from "./ManageTV/ManageTV";
import {
  EventType,
  PropertyDetailsType,
  RecommendationType,
  TvScreenType,
} from "../../types";
import updateNestedState from "../../utils/updateNestedState";
import UpcomingEvents from "../../pages/PropertyDetails/UpcomingEvents";
import Recommendations from "../../pages/PropertyDetails/Recommendations";
import WifiBlock from "../../pages/PropertyDetails/WifiBlock";
import ContactsBlock from "../../pages/PropertyDetails/ContactsBlock";
import QrCodeBlock from "../../pages/PropertyDetails/QrCodeBlock";
import WeatherBlock from "../../pages/PropertyDetails/WeatherBlock";
import axios, { AxiosResponse } from "axios";
import BackgroundBlock from "../../pages/PropertyDetails/BackgroundBlock";
import CarouselBlock from "./CarouselBlock";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BasicModal } from "../../components/BasicModal";
import { ErrorOutline } from "@mui/icons-material";
import FontColorSwitch from "./FontColorSwitch";

type GalleryImage = {
  id: string;
  url: {
    thumb: string;
    medium: string;
    original: string;
  };
};

type ModalStatus = {
  status: string;
  type: string;
};

const PropertyDetails = () => {
  const propertyId = useParams().id;
  const navigate = useNavigate();

  const [formData, setFormData] = useState<PropertyDetailsType | null>(null);
  const [gallery, setGallery] = useState<GalleryImage[]>([]);
  const [recommendations, setRecommendations] = useState<RecommendationType[]>(
    [],
  );
  const [events, setEvents] = useState<EventType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [statusModal, setStatusModal] = useState<ModalStatus | null>(null);

  const updateValue = (path: string, value: unknown) => {
    setFormData((prevState: PropertyDetailsType | null) => {
      if (prevState === null) return prevState;
      return updateNestedState(prevState, path, value);
    });
  };

  const handleSubmit = async () => {
    setIsButtonLoading(true);
    try {
      const formattedFormData = {
        name: formData?.property?.name,
        location: formData?.property.place?.id,
        welcome_greeting: formData?.property?.welcome_greeting,
        welcome_message: formData?.property?.welcome_message,
        wifi_network: formData?.property?.wifi_network,
        wifi_password: formData?.property?.wifi_password,
        contact_email: formData?.property?.contact_email,
        contact_phone: formData?.property?.contact_phone,
        image_id: formData?.property.image?.id,
        background_id: formData?.property?.background?.id || null,
        gallery: gallery.map((img) => img.id),
        qr_code: formData?.property?.qr_code?.id,
        video_url: formData?.property?.video_url,
        use_video: formData?.property?.use_video,
        use_white_font: formData?.property?.use_white_font,
        events: events.map((event) => ({
          name: event.name,
          datetime: moment(event.datetime).format("YYYY-MM-DDTHH:mm:ssZ"),
        })),
        recommendations: recommendations.map((rec) => ({
          ...rec,
          category: undefined,
          category_id: rec.category?.id,
        })),
      };
      axios
        .patch(`/api/properties/${propertyId}`, formattedFormData)
        .then(() => {
          setStatusModal({
            status: "Property was successfully updated!",
            type: "success",
          });
        });
    } catch (error) {
      console.error("Error submitting form", error);
      setStatusModal({ status: "Error updating property", type: "error" });
    } finally {
      setIsButtonLoading(false);
    }
  };

  const updateData = (response: AxiosResponse) => {
    setFormData((prev) => ({
      ...prev,
      property: response.data,
      screens: response.data.screens,
      events: response.data.events,
      recommendations: response.data.recommendations,
    }));
    setGallery(response.data.gallery);
    setRecommendations(response.data.recommendations);
    setEvents(response.data.events);
  };

  const navigateToTvDisplayPreview = async () => {
    window.open(`/welcome-screen/preview/${propertyId}`, "_blank");
  };

  useEffect(() => {
    if (formData !== null) return;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/properties/${propertyId}`);
        updateData(response);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const removeGalleryImage = (id: string) => {
    setGallery((prev) => prev.filter((img) => img.id !== id));
  };

  const addGalleryImage = (image: GalleryImage) => {
    setGallery((prev) => [...prev, image]);
  };

  const removeRecommendation = (deleteRec: RecommendationType) => {
    setRecommendations((prevState) =>
      prevState.filter((rec) => {
        if (deleteRec.id) {
          return rec.id !== deleteRec.id;
        } else {
          return rec.name !== deleteRec.name;
        }
      }),
    );
  };

  const editRecommendation = (rec: RecommendationType) => {
    setRecommendations((prevState) =>
      prevState.map((obj) => (obj.id === rec.id ? rec : obj)),
    );
  };

  const removeEvent = (deleteEvent: EventType) => {
    setEvents((prevState) =>
      prevState.filter((event) => {
        if (deleteEvent.id) {
          return event.id !== deleteEvent.id;
        } else {
          return event.name !== deleteEvent.name;
        }
      }),
    );
  };

  const editEvent = (event: EventType) => {
    setEvents((prevState) =>
      prevState.map((obj) => (obj.id === event.id ? event : obj)),
    );
  };

  return isLoading ? (
    <Wrapper>
      <Row>
        <Col bg>
          <LoaderWrapper>
            <CircularProgress color="inherit" />
          </LoaderWrapper>
        </Col>
      </Row>
    </Wrapper>
  ) : (
    <Wrapper>
      <Row>
        <Col bg>
          <ManageTv
            tv_list={formData?.screens}
            setTVList={(screens: TvScreenType[]) =>
              setFormData((prevState) => {
                if (!prevState) return prevState;
                return { ...prevState, screens: screens };
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={3} bg>
          <WelcomeMessage
            welcomeMessage={formData?.property.welcome_message || ""}
            welcomeGreeting={formData?.property.welcome_greeting || ""}
            onGreetingChange={(str: string) =>
              updateValue("property.welcome_greeting", str)
            }
            onMessageChange={(str: string) =>
              updateValue("property.welcome_message", str)
            }
          />
        </Col>
        <Col span={2} noPadding>
          <Row>
            <Col bg>
              <CarouselBlock
                onImageSubmit={addGalleryImage}
                onImageRemove={removeGalleryImage}
                videoUrl={formData?.property.video_url || ""}
                setVideoUrl={(str) => updateValue("property.video_url", str)}
                useVideo={!!formData?.property.use_video}
                setUseVideo={(state) =>
                  updateValue("property.use_video", state)
                }
                gallery={gallery}
              />
            </Col>
          </Row>
          <Row>
            <Col bg>
              <BackgroundBlock
                value={formData?.property?.background?.url?.medium}
                onBGSelect={(image) =>
                  updateValue("property.background", image)
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col bg>
          <UpcomingEvents
            events={events}
            onDeleteEvent={removeEvent}
            onEditEvent={editEvent}
            onAddEvent={(event) =>
              setEvents((prevState) => [...prevState, event])
            }
          />
        </Col>
        <Col bg>
          <Recommendations
            recommendations={recommendations}
            onDeleteRecommendation={removeRecommendation}
            onEditRecommendation={editRecommendation}
            onAddRecommendation={(rec) =>
              setRecommendations((prevState) => [...prevState, rec])
            }
          />
        </Col>
      </Row>
      <Row>
        <Col bg>
          <WifiBlock
            wifi_network={formData?.property.wifi_network}
            wifi_password={formData?.property.wifi_password}
            onNetworkChange={(str: string) =>
              updateValue("property.wifi_network", str)
            }
            onPasswordChange={(str: string) =>
              updateValue("property.wifi_password", str)
            }
          />
        </Col>
        <Col bg>
          <ContactsBlock
            contact_email={formData?.property.contact_email}
            contact_phone={formData?.property.contact_phone}
            onEmailChange={(str: string) =>
              updateValue("property.contact_email", str)
            }
            onPhoneChange={(str: string) =>
              updateValue("property.contact_phone", str)
            }
          />
        </Col>
      </Row>
      <Row>
        <Col bg>
          <WeatherBlock
            value={formData?.property?.place?.address || ""}
            onChange={(str: string) => updateValue("property.location", str)}
          />
        </Col>
        <Col bg>
          <QrCodeBlock
            value={formData?.property?.qr_code?.url?.split("/").at(-1) || ""}
            onQRUpload={(qr) => updateValue("property.qr_code", qr)}
          />
        </Col>
      </Row>
      <Row justify="space-evenly">
        <Col bg>
          <FontColorSwitch
            value={formData?.property?.use_white_font || false}
            onChange={(value) => updateValue("property.use_white_font", value)}
          />
        </Col>
        <Col />
      </Row>
      <Row justify={"end"}>
        <Button onClick={navigateToTvDisplayPreview}>Review display</Button>
        <Button onClick={() => handleSubmit()} primary>
          {isButtonLoading ? (
            <CircularProgress color="inherit" size="1em" />
          ) : (
            "Update"
          )}
        </Button>
      </Row>
      <BasicModal open={!!statusModal} onClose={() => setStatusModal(null)}>
        <ModalContainer>
          <ModalTitle>
            {statusModal?.type === "success" ? (
              <CheckCircleOutlineIcon fontSize="inherit" />
            ) : (
              <ErrorOutline fontSize="inherit" />
            )}
          </ModalTitle>
          <p>{statusModal?.status}</p>
          <ButtonContainer>
            <Button onClick={() => setStatusModal(null)} inModal>
              Close{" "}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </BasicModal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
`;

const Row = styled.div<{ justify?: string }>`
  display: flex;
  flex-direction: row;
  height: fit-content;
  flex: 1;
  gap: 20px;
  width: 100%;
  justify-content: ${(props) => props.justify};
`;

const Col = styled.div<{ span?: number; bg?: boolean; noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: ${(props) => props.span ?? 1};
  gap: 20px;
  background: ${(props) => (props.bg ? "#fff" : "none")};
  border-radius: 20px;
  box-sizing: border-box;
  padding: ${(props) => (props.noPadding ? "0" : "25px 30px")};
  justify-content: ${(props) => (props.bg ? "unset" : "space-evenly")};
`;

const Button = styled.button<{ primary?: boolean; inModal?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: ${(props) => (props.inModal ? "0" : "30px")};
  margin-bottom: ${(props) => (props.inModal ? "0" : "30px")};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  padding: 0 10px;

  & > p {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 30px 0;
  }
`;

const ModalTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 48px;
  font-weight: 400;
  width: 500px;
  text-align: center;
  color: #1982fc;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: auto;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-column: span 2;
  color: #1982fc;
`;

export default PropertyDetails;
