import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { ReducedProperty } from './Properties';
import ManualForm from './ManualForm';
import PmsForm from './PMSForm';
import PMSPropertyList from './PMSPropertyList';
import axios from 'axios';

type Props = {
  onClose: () => void;
  onAddProperty: (property: ReducedProperty) => void;
  onEditProperty: (property: ReducedProperty) => void;
  property: ReducedProperty | null;
};

export type PMSProperty = {
  id: string;
  internalName: string | null;
  name: string | null;
  type: string | null;
  thumbnailUrl: string | null;
  city: string | null;
  country: string | null;
  addressLine: string | null;
  postalCode: string | null;
  state: string | null;
  latitude: number | null;
  longitude: number | null;
};

const propertiesList = [
  { id: '1', name: 'Property #1' },
  { id: '2', name: 'Property #2' },
  { id: '3', name: 'Property #3' },
];

export const AddNewPropertyModal = forwardRef(
  (
    { onClose, onAddProperty, onEditProperty, property }: Props,
    ref
  ) => {
    const [formType, setFormType] = useState('manual');
    const [isPMSLinked, setIsPMSLinked] = useState(false);
    const [PMSError, setPMSError] = useState('');
    const [propertiesList, setPropertiesList] = useState<
      PMSProperty[]
    >([]);

    useImperativeHandle(ref, () => ({
      setFormManual: () => setFormType('manual'),
      setFormPMS: () => setFormType('pms'),
      setIsPMSLinked: (isLinked: boolean) => setIsPMSLinked(isLinked),
      setPMSError: (error: string) => setPMSError(error),
    }));

    useEffect(() => {
      if (isPMSLinked) {
        axios.get('/api/pms/properties').then((res) => {
          setPropertiesList(res.data);
        });
      }
    }, [isPMSLinked]);

    const renderFormSwitch = () => {
      return (
        <>
          <FormSwitch>
            <FormSwitchButton
              active={formType === 'manual'}
              onClick={() => setFormType('manual')}
            >
              Manual
            </FormSwitchButton>
            <FormSwitchButton
              active={formType === 'pms'}
              onClick={() => setFormType('pms')}
            >
              PMS
            </FormSwitchButton>
          </FormSwitch>
          {formType === 'manual' ? (
            <ManualForm
              onClose={onClose}
              onAddProperty={onAddProperty}
              onEditProperty={onEditProperty}
              property={property}
            />
          ) : isPMSLinked ? (
            <PMSPropertyList
              propertiesList={propertiesList}
              onClose={onClose}
            />
          ) : (
            <PmsForm
              error={PMSError}
              setError={setPMSError}
              onClose={onClose}
              onContinue={() => null}
            />
          )}
        </>
      );
    };

    return (
      <ModalContainer>
        <Title>Add New Property</Title>
        {renderFormSwitch()}
      </ModalContainer>
    );
  }
);

const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  padding: 0 20px;
  width: 650px;
`;

const FormSwitchButton = styled.div<{ active?: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: ${(props) => (props.active ? '400' : '600')};
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-bottom: 2px solid
    ${(props) => (props.active ? '#1982FC' : '#a8a6a6')};
  box-sizing: border-box;
  color: ${(props) => (props.active ? '#000' : '#A8A6A6')};
  margin-bottom: -2px;
  cursor: pointer;
  padding: 0 10px;
`;

const FormSwitch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
  border-bottom: 2px solid #a8a6a6;
  box-sizing: border-box;
`;
