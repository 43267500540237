import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as yup from "yup";
import SignUpForm from "./SignUpForm";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const validationSignupSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

function Index() {
  const navigate = useNavigate();
  const [googleRedirect, setGoogleRedirect] = useState("");
  const [facebookRedirect, setFacebookRedirect] = useState("");

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    setFieldError,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      verificationCode: "",
    },
    validateOnChange: false,
    validationSchema: validationSignupSchema,
    onSubmit: (values) => signUp(values),
  });

  const signUp = async (values: FormValues) => {
    await axios
      .post("/api/auth/register", {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        navigate("/signup/verify-email", {
          state: {
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setFieldError("email", "Email already exists");
        } else if (error.response) {
          setFieldError("email", "Internal server error, try again later");
        }
      });
  };

  useEffect(() => {
    const getSocialRedirect = async () => {
      const google = await axios.get("/api/auth/google/redirect");
      const facebook = await axios.get("/api/auth/facebook/redirect");
      setGoogleRedirect(google.data.url);
      setFacebookRedirect(facebook.data.url);
    };
    getSocialRedirect();
  }, []);

  return (
    <SignupWrapper>
      <FormHalf>
        <FormHeader>
          <Logo>
            <img src="/logo-wide.png" alt="Logo" />
          </Logo>
          <SigninText>
            Already have an account?{" "}
            <SigninLink href="/login">Sign in</SigninLink>
          </SigninText>
        </FormHeader>
        <SignUpForm
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          googleRedirect={googleRedirect}
          facebookRedirect={facebookRedirect}
        />
      </FormHalf>
      <ImageHalf>
        <BulletPoints>
          <BulletPoint>
            <BulletPointIcon src="/following.svg" />
            <BulletPointText>Personalized Guest Experience </BulletPointText>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src="/recommendations.svg" />
            <BulletPointText>Local Recommendations </BulletPointText>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src="/list.svg" />
            <BulletPointText>Key Information at Glance </BulletPointText>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src="/weather.svg" />
            <BulletPointText>Event and Weather Updates </BulletPointText>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src="/call-missed.svg" />
            <BulletPointText>Reduced Communication Overhead </BulletPointText>
          </BulletPoint>
        </BulletPoints>
        <BulletPointPromoText>
          Effortlessly enhance your guest experience with personalized,
          informative welcome screens.
        </BulletPointPromoText>
      </ImageHalf>
    </SignupWrapper>
  );
}

const SignupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
`;
const FormHalf = styled.div`
  flex: 0 0 51%;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const FormHeader = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    height: 40px;
    width: 100%;
    object-fit: contain;
  }

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
`;

const SigninText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  text-align: left;
  font-optical-sizing: auto;
  margin: 0;
  color: #5c5c5c;
`;

const SigninLink = styled.a`
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  appearance: none;
  color: #5c5c5c;
`;
const ImageHalf = styled.div`
  flex: 0 0 49%;
  background-image: url("/signup-bg.jpeg");
  background-size: cover;
  background-color: #000000b8;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BulletPoints = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 25%;
`;

const BulletPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BulletPointIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const BulletPointText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: white;
  margin: 0 0 0 40px;
`;

const BulletPointPromoText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: white;
  text-align: left;
  margin-top: 176px;
  margin-left: 25%;
  width: 50%;
`;

export default Index;
