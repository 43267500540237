import React from "react";
import { SideBar } from "./SideBar";
import { TopMenu } from "./TopMenu";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

export const MainLayout = () => {
  return (
    <MainWrap>
      <div>
        <SideBar />
      </div>
      <MainSection>
        <TopMenu />
        <PageContentWrap>
          <Outlet />
        </PageContentWrap>
      </MainSection>
    </MainWrap>
  );
};

const MainSection = styled.main`
  overflow: scroll;
`;

const MainWrap = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  padding: 0;
  background: #f1f5f9;
  height: 100vh;
`;

const PageContentWrap = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  padding: 80px 20px 0 20px;
`;
