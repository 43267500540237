import React, { useState } from "react";
import styled from "@emotion/styled";
import { TvScreenType } from "../../../types";
import { AddOutlined } from "@mui/icons-material";
import ScreenItem from "./ScreenItem";
import { useFormik } from "formik";
import axios from "axios";
import { BasicModal } from "../../../components/BasicModal";
import { useParams } from "react-router-dom";

type ManageTvProps = {
  tv_list?: TvScreenType[];
  setTVList: (tv_list: TvScreenType[]) => void;
};

function ManageTv({ tv_list, setTVList }: ManageTvProps) {
  const propertyId = useParams().id;
  const [isEditMode, setIsEditMode] = useState(false);
  const [isNamingOpen, setIsNamingOpen] = useState(false);
  const [isCodeOpen, setIsCodeOpen] = useState(false);

  const startEdit = (tv: TvScreenType) => {
    setIsEditMode(true);
    setValues(tv);
    setIsNamingOpen(true);
  };

  const onCancel = () => {
    setIsNamingOpen(false);
    setIsEditMode(false);
    resetForm();
  };

  const onDelete = (id: string) => {
    axios.delete(`/api/properties/${propertyId}/screens/${id}`).then(() => {
      setTVList(tv_list?.filter((tv) => tv.id !== id) || []);
    });
  };

  const onLinkClick = (tv: TvScreenType) => {
    setIsCodeOpen(true);
    setValues(tv);
  };

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    setValues,
    resetForm,
    submitForm,
  } = useFormik({
    initialValues: {
      id: "",
      name: "",
      access_code: "",
      is_active: false,
    },
    onSubmit: (values) => {
      if (isEditMode) {
        axios
          .put(`/api/properties/${propertyId}/screens/${values.id}`, {
            name: values.name,
          })
          .then(() => {
            setTVList(
              tv_list?.map((tv: TvScreenType) => {
                if (tv.id === values.id) {
                  return {
                    ...tv,
                    name: values.name,
                  };
                }
                return tv;
              }) || [],
            );
            setIsNamingOpen(false);
            setIsEditMode(false);
            resetForm();
          });
      } else {
        axios
          .post(`/api/properties/${propertyId}/screens`, {
            name: values.name,
          })
          .then((res) => {
            setTVList([...(tv_list || []), res.data]);
            setFieldValue("access_code", res.data.access_code);
            setFieldValue("id", res.data.id);
            setFieldValue("name", "");
            setIsNamingOpen(false);
            setIsCodeOpen(true);
          });
      }
    },
  });

  return (
    <>
      <Title>
        Manage TV Connection
        {tv_list && tv_list.length < 3 && (
          <Add onClick={() => setIsNamingOpen(true)}>
            <AddOutlined fontSize={"inherit"} />
          </Add>
        )}
      </Title>
      <div>
        {tv_list?.map((tv, index) => (
          <ScreenItem
            id={tv.id || ""}
            is_active={tv.is_active || false}
            key={index}
            name={tv.name || ""}
            onEditClick={() => startEdit(tv)}
            onDeleteClick={() => onDelete(tv.id || "")}
            onLinkClick={() => onLinkClick(tv || "")}
          />
        ))}
      </div>
      <BasicModal open={isNamingOpen} onClose={() => setIsNamingOpen(false)}>
        <ModalContainer>
          <ModalTitle>{isEditMode ? "Edit" : "Add"} Screen</ModalTitle>
          <InputContainer>
            <InputWrapper>
              <TextFieldStyled
                placeholder="Screen Name"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <FieldError error={errors.name}>{errors.name}</FieldError>
            </InputWrapper>
          </InputContainer>
          <ButtonContainer>
            <Button onClick={() => onCancel()}>Discard </Button>
            <Button onClick={submitForm} primary>
              {isEditMode ? "Submit" : "Add"}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </BasicModal>

      <BasicModal open={isCodeOpen} onClose={() => setIsCodeOpen(false)}>
        <ModalContainer>
          <ContentContainer>
            <img src="/Remote.svg" alt="logo" />
            <CodeText>
              Open app store on your Android TV and download the App
            </CodeText>
            <img src="/Logo.svg" alt="logo" />
            <LogoTitle>ZoneSage</LogoTitle>
            <CodeText>To access the application in your TV</CodeText>
            <CodeText>
              Please enter this 6 digit code in your television.
            </CodeText>
            <AccessCodeContainer>
              {values?.access_code
                ?.split("")
                .map((letter, index) => (
                  <AccessCodeLetter key={index}>
                    {letter.toUpperCase()}
                  </AccessCodeLetter>
                ))}
            </AccessCodeContainer>
          </ContentContainer>
          {/*<ButtonContainer>*/}
          <Button onClick={() => setIsCodeOpen(false)}>Close </Button>
          {/*</ButtonContainer>*/}
        </ModalContainer>
      </BasicModal>
    </>
  );
}

export default ManageTv;

const Title = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
`;

const Add = styled.div`
  cursor: pointer;
  margin-left: auto;
  font-size: 28px;
  line-height: 28px;
  color: #0076ff;
`;

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  padding: 0 20px;
  width: 650px;
`;

const ModalTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 30px;
  margin-right: auto;
  margin-bottom: 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const FieldError = styled.div<{ error?: string }>`
  display: ${(props) => (props?.error ? "block" : "none")};
  color: #ff8b8b;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 15px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const LogoTitle = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 26.67px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: center;
  color: #1982fc;
  padding: 0;
  margin: 0;
`;

const CodeText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding: 0;
  margin: 0;
`;

const AccessCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
const AccessCodeLetter = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding: 20px;
  color: #1982fc;
  background-color: #1982fc33;
  border-radius: 5px;
  margin: 0;
`;
