import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import Row from "../components/Row";
import AuthInput from "../components/AuthInput";
import Button from "../components/Button";
import axios from "axios";

const validationResetPasswordSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

function Index() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const token = params.get("token");
  const email = params.get("email");

  if (!token || !email) {
    navigate("/forgot-password");
  }

  const { values, errors, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        token: token || "",
        email: email || "",
        password: "",
        confirmPassword: "",
      },
      validateOnChange: false,
      validationSchema: validationResetPasswordSchema,
      onSubmit: () => changePassword(),
    });

  useEffect(() => {
    setFieldValue("token", token);
    setFieldValue("email", email);
  }, [params, token, email]);

  const changePassword = () => {
    axios
      .post("/api/auth/reset-password", {
        email: values.email,
        password: values.password,
        password_confirmation: values.confirmPassword,
        token: values.token,
      })
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <ForgotPasswordWrapper>
      <FormWrapper>
        <FormHeader>
          <Logo>
            <img src="/logo-wide.png" alt="Logo" />
          </Logo>
          <SignupText>
            Don’t have an account?{" "}
            <SignupLink href="/signup">Sign up </SignupLink>
          </SignupText>
        </FormHeader>
        <FormContainer>
          <Form>
            <Row>
              <FormTitle>Create new password</FormTitle>
            </Row>
            <Row>
              <AuthInput
                type="password"
                name="password"
                placeholder="New Password"
                value={values.password}
                onChange={handleChange}
                error={errors.password}
              />
            </Row>
            <Row>
              <AuthInput
                type="password"
                name="confirmPassword"
                placeholder="Confirm New Password"
                value={values.confirmPassword}
                onChange={handleChange}
                error={errors.confirmPassword}
              />
            </Row>

            <ButtonContainer>
              <BigButton onClick={handleSubmit}>Continue</BigButton>
            </ButtonContainer>
          </Form>
        </FormContainer>
      </FormWrapper>
    </ForgotPasswordWrapper>
  );
}

const ForgotPasswordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
  width: 100%;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const FormHeader = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    height: 40px;
    width: 100%;
    object-fit: contain;
  }

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
`;

const SignupText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  text-align: left;
  font-optical-sizing: auto;
  margin: 0;
  color: #5c5c5c;
`;

const SignupLink = styled.a`
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  appearance: none;
  color: #5c5c5c;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const FormTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 100%;
  justify-content: center;
  gap: 20px;
`;

const Description = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin: 0 0 30px 0;
  color: #000000;
`;

const BigButton = styled(Button)`
  flex: 2;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 25px;
`;

export default Index;
