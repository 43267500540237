import React from "react";
import styled from "@emotion/styled";
import prop_bg from "../../icons/prop_bg.jpeg";
import Button from "@mui/material/Button";
import { ImageType } from "../../types";

type Props = {
  name: string;
  image: ImageType | null;
  id: string;
  handleEdit: () => void;
  handleDelete: () => void;
};
export const SingleProperty = ({
  name,
  id,
  image,
  handleEdit,
  handleDelete,
}: Props) => {
  return (
    <PropertyWrapper href={`properties/${id}/edit`}>
      <ImgWrapper>
        <img src={image?.url.medium || prop_bg} />
      </ImgWrapper>
      <Description>
        <Title>{name}</Title>
        <Actions>
          <ButtonStyled
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleEdit();
            }}
          >
            Edit
          </ButtonStyled>
          <ButtonStyled
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleDelete();
            }}
            color={"error"}
          >
            Delete
          </ButtonStyled>
        </Actions>
      </Description>
    </PropertyWrapper>
  );
};

const ButtonStyled = styled(Button)`
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 20px !important;
  height: 32px;
`;

const PropertyWrapper = styled.a`
  display: grid;
  grid-template-rows: 162px 1fr;
  border-radius: 5px;
  border: 1px solid #64748b;
  text-decoration: none;
  color: #000;

  img {
    width: 100%;
    height: 162px;
  }
`;

const ImgWrapper = styled.div``;

const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
`;

const Actions = styled.div`
  gap: 10px;
  display: flex;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  margin-right: 15px;
`;
