import React from "react";
import styled from "@emotion/styled";
import {
  DeleteOutlined,
  DriveFileRenameOutlineOutlined,
  LinkOutlined,
} from "@mui/icons-material";

type ScreenItemProps = {
  id: string;
  name: string;
  is_active: boolean;
  onLinkClick: (id: string) => void;
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
};

function ScreenItem({
  id,
  name,
  is_active,
  onLinkClick,
  onEditClick,
  onDeleteClick,
}: ScreenItemProps) {
  return (
    <ItemContainer is_active={is_active}>
      <Dot is_active={is_active} />
      <ScreenName is_active={is_active}>{name}</ScreenName>
      <IconsBlock>
        {is_active ? null : (
          <LinkOutlined
            color="inherit"
            className="blue"
            onClick={() => onLinkClick(id)}
          />
        )}
        <DriveFileRenameOutlineOutlined
          color="inherit"
          className="blue"
          onClick={() => onEditClick(id)}
        />
        <DeleteOutlined
          color="inherit"
          className="red"
          onClick={() => onDeleteClick(id)}
        />
      </IconsBlock>
    </ItemContainer>
  );
}

export default ScreenItem;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid
    ${(props: { is_active?: boolean }) =>
      props.is_active ? "#05DE8F" : "#a8a6a6"};
  border-radius: 10px;
  margin-bottom: 15px;
`;

const ScreenName = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: ${(props: { is_active?: boolean }) =>
    props.is_active ? "#05DE8F" : "#a8a6a6"};
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 15px;
`;

const IconsBlock = styled.div`
  display: flex;
  margin-left: auto;
  gap: 120px;
  color: #a8a6a6;

  .blue:hover {
    color: #7ab5fa;
    cursor: pointer;
  }

  .red:hover {
    color: #f35b5b;
    cursor: pointer;
  }
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props: { is_active?: boolean }) =>
    props.is_active ? "#05DE8F" : "#d9d9d9"};
`;
