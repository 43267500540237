import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styled from '@emotion/styled';
import Button from '../components/Button';
import AuthInput from '../components/AuthInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { LocationType } from '../../../types';
import Cookies from 'js-cookie';

type Property = {
  name: string;
  location: string;
};

const validationPropertySchema = yup.object().shape({
  name: yup.string().required('Property name is required'),
  location: yup.string().required('Location is required'),
});

function Index() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formShown, setFormShown] = React.useState(false);
  const [properties, setProperties] = React.useState<Property[]>([]);
  const [locationOptions, setLocationOptions] = useState<
    LocationType[]
  >([]);
  const [name, setName] = useState('');
  const [locationSelected, setLocationSelected] = useState(true);
  const [locationInput, setLocationInput] = useState('');
  const [params] = useSearchParams();
  const param_auth_token = params.get('token');
  const cookie_auth_token = Cookies.get('auth_token');

  const {
    values,
    errors,
    handleChange,
    validateForm,
    setFieldValue,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: '',
      location: '',
    },
    validateOnChange: false,
    validationSchema: validationPropertySchema,
    onSubmit: (val) => createProperty(val),
  });

  const createProperty = (property: Property) => {
    axios
      .post('/api/properties', {
        name: property.name,
        location: property.location,
      })
      .then((res) => {
        setProperties((prev) => [
          ...prev,
          {
            name: property.name,
            location: property.location,
          },
        ]);
        resetForm();
        setLocationInput('');
        setLocationOptions([]);
      });
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (locationInput && !locationSelected) {
        axios
          .get(`/api/find-location?input=${locationInput}`)
          .then((response) => {
            setLocationOptions(response.data);
          })
          .catch((error) => {
            console.error('Error getting locations:', error);
            setLocationOptions([]);
          });
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [locationInput, locationSelected]);

  useEffect(() => {
    if (param_auth_token) {
      Cookies.set('auth_token', param_auth_token);
      axios
        .get('/api/session', {
          headers: { Authorization: `Bearer ${param_auth_token}` },
        })
        .then(() => {
          navigate(`/onboarding`);
        });
    } else if (cookie_auth_token) {
      axios
        .get('/api/session', {
          headers: { Authorization: `Bearer ${cookie_auth_token}` },
        })
        .then((response) => {
          setName(
            [
              response.data.user.first_name || '',
              response.data.user.last_name || '',
            ].join(' ')
          );
        });
    }
  }, [param_auth_token, cookie_auth_token]);

  return (
    <OnboardingWrapper>
      <ScreenWrapper>
        <ScreenHeader>
          <Logo>
            <img src="/logo-wide.png" alt="Logo" />
          </Logo>
        </ScreenHeader>
        <FormContainer>
          {!formShown ? (
            <WelcomeMessage
              name={name}
              goToForm={() => setFormShown(true)}
            />
          ) : (
            <FormWrapper>
              <FormTitle>Add Property</FormTitle>
              <FormDescription>
                Let’s add your properties first! Don’t worry – you can
                change or edit your properties later.
              </FormDescription>
              <FormDisclaimer>
                <Star>*</Star>Indicates a required field
              </FormDisclaimer>
              <SplitView>
                <Form>
                  <InputContainer>
                    <AuthInput
                      type="text"
                      name="name"
                      placeholder=""
                      value={values.name}
                      error={errors.name}
                      label="Property name"
                      required
                      onChange={handleChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <AuthInput
                      type="text"
                      name="location"
                      placeholder=""
                      value={locationInput}
                      error={errors.location}
                      label="Address"
                      required
                      onChange={(e) => {
                        setLocationSelected(false);
                        setLocationInput(e.target.value);
                      }}
                    />
                    <Dropdown options={!!locationOptions.length}>
                      {locationOptions.map((locationOption) => (
                        <DropdownItem
                          key={locationOption.placeId}
                          onClick={() => {
                            setFieldValue(
                              'location',
                              locationOption.placeId
                            );
                            setLocationInput(locationOption.address);
                            setLocationSelected(true);
                            setLocationOptions([]);
                          }}
                        >
                          {locationOption.address}
                        </DropdownItem>
                      ))}
                    </Dropdown>
                  </InputContainer>
                  <ButtonContainer>
                    <Button
                      onClick={handleSubmit}
                      variant={'secondary'}
                    >
                      + Add property
                    </Button>
                    <Button
                      onClick={() => navigate('/properties')}
                      disabled={!properties.length}
                    >
                      Continue
                    </Button>
                  </ButtonContainer>
                </Form>
                <PropertyList>
                  {properties.map((property) => (
                    <PropertyItem key={property.name}>
                      <PropertyName>{property.name}</PropertyName>
                    </PropertyItem>
                  ))}
                </PropertyList>
              </SplitView>
            </FormWrapper>
          )}
        </FormContainer>
      </ScreenWrapper>
    </OnboardingWrapper>
  );
}

const WelcomeMessage = ({
  name,
  goToForm,
}: {
  name: string;
  goToForm: () => void;
}) => {
  return (
    <WelcomeMessageWrapper>
      <WelcomeMessageTitle>Welcome {name}!</WelcomeMessageTitle>
      <WelcomeMessageText>
        Ready to create a personalized experience for your guests?{' '}
        <br />
        This will only take a few minutes . . .
      </WelcomeMessageText>
      <ButtonContainer contained>
        <Button onClick={goToForm}>Continue</Button>
      </ButtonContainer>
    </WelcomeMessageWrapper>
  );
};

const OnboardingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const ScreenWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
`;

const ScreenHeader = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  flex-direction: row;
  padding: 20px 50px;
  border-bottom: 3px solid #1472ff;
  box-sizing: border-box;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    height: 40px;
    width: 100%;
    object-fit: contain;
  }

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const WelcomeMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const WelcomeMessageTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  text-align: center;
  margin: 0 0 38px 0;
`;

const WelcomeMessageText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
  margin: 0 0 80px 0;
`;

const ButtonContainer = styled.div<{ contained?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: ${({ contained }) => (contained ? '210px' : 'auto')};
  margin-top: 20px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 0 170px;
`;

const FormTitle = styled.p`
  font-family: Inter, sans-serif;
  margin: 0 0 35px 0;
  text-align: left;
  width: 100%;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
`;

const FormDescription = styled.p`
  font-family: Inter, sans-serif;
  margin: 0 0 10px 0;
  text-align: left;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
`;

const FormDisclaimer = styled.p`
  font-family: Inter, sans-serif;
  margin: 0 0 90px 0;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #575757;
`;

const Star = styled.span`
  color: red;
`;

const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 130px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  position: relative;
`;

const PropertyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  overflow-y: scroll;
  max-height: 400px;
`;

const PropertyItem = styled.div`
  padding: 20px 31px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background: #f7f7f7;
`;

const PropertyName = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`;

const Dropdown = styled.div<{ options: boolean }>`
  position: absolute;
  display: ${(props) => (props.options ? 'block' : 'none')};
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #a8a6a6;
  border-radius: 6px;
  z-index: 1;
  overflow: hidden;
`;

const DropdownItem = styled.div`
  padding: 5px 10px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export default Index;
