import React from "react";
import styled from "@emotion/styled";

type AuthInputProps = {
  label?: string;
  type: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  error?: string;
  maxlength?: number;
  required?: boolean;
};

function AuthInput({
  label,
  type,
  placeholder,
  value,
  name,
  onChange,
  error,
  maxlength,
  required,
}: AuthInputProps) {
  return (
    <InputWrapper>
      {label && (
        <Label>
          {required && <Star>*</Star>} {label}
        </Label>
      )}
      <Input
        name={name}
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxlength}
      />
      {error && <Error>{error}</Error>}
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.label`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 15px;
`;

const Input = styled.input`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 18px 24px;
  border: 1px solid #5858587f;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;

  & :placeholder {
    color: #585858;
  }
`;

const Error = styled.span`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: red;
`;

const Star = styled.span`
  color: red;
`;

export default AuthInput;
