import { DateTime } from 'luxon';

export const getDateByTimezone = (timezone: string, currentTime: Date) => {
    const dt = DateTime.fromJSDate(currentTime).setZone(timezone);

    const timeString = dt.toFormat('h:mm a');

    const dateString = dt.toFormat('EEE, MMM d');

    return { timeString, dateString };
};
