import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import styled from "@emotion/styled";

const menuItems = [
  { label: "Display", value: "/edit" },
  { label: "Guest List", value: "/guests" },
];

function DetailsLayout() {
  const location = useLocation();
  return (
    <LayoutWrapper>
      <Menu>
        {menuItems.map((el, i) => (
          <MenuItem
            key={i}
            active={location.pathname.includes(el.value)}
            to={"." + el.value}
          >
            <p>{el.label}</p>
          </MenuItem>
        ))}
      </Menu>
      <Outlet />
    </LayoutWrapper>
  );
}

const LayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 18px;
`;

const MenuItem = styled(Link)<{ active?: boolean }>`
  padding: 13px 30px;
  appearance: none;
  text-decoration: none;
  border-bottom: 3px solid ${(props) => (props.active ? "#1982FC" : "#A8A6A6")};

  & > p {
    font-family: Roboto, sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 24px;
    color: ${(props) => (props.active ? "#1982FC" : "#A8A6A6")};
    margin: 0;
  }
`;

export default DetailsLayout;
