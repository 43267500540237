import React, { useEffect, useRef, useState } from "react";
import { SearchInput } from "../../components/SearchInput";
import styled from "@emotion/styled";
import { BasicModal } from "../../components/BasicModal";
import { AddNewPropertyModal } from "./AddNewPropertyModal";
import { SingleProperty } from "./SingleProperty";
import { PaginationLinkType, PropertyType } from "../../types";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import renderPaginationButtons from "../../utils/renderPaginationButtons";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AddButton } from "./AddButton";

export interface Properties {
  data: PropertyType[];
  links: PaginationLinkType[];
  current_page: number;
  last_page: number;
}

export type ReducedProperty = Pick<
  PropertyType,
  'id' | 'name' | 'image' | 'location' | 'place'
>;

export const Properties = () => {
  const [properties, setProperties] = useState<Properties>();
  const [openModal, setOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [propsList, setPropsList] = useState<
    ReducedProperty[] | null
  >(null);
  const [selectedProperty, setSelectedProperty] =
    useState<ReducedProperty | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const auth_token = params.get('token');
  const modalRef = useRef<{
    setIsPMSLinked: (b: boolean) => void;
    setFormPMS: () => void;
    setPMSError: (error: string) => void;
  }>();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get('/api/properties', {
        params: {
          search: searchValue,
          page: page,
        },
      })
      .then(function (response) {
        setProperties(response.data);
        setPropsList(response.data.data);
        setTotalPages(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [searchValue, page]);

  const handleDelete = (id: string) => {
    axios.delete(`/api/properties/${id}`).then(() => {
      setPropsList((prev) => {
        return prev?.filter((prop) => prop?.id !== id) || [];
      });
    });
  };

  useEffect(() => {
    if (auth_token) {
      Cookies.set('auth_token', auth_token);
      navigate('/properties');
    }
  }, [auth_token]);

  const handleEdit = (prop: ReducedProperty) => {
    setSelectedProperty(prop);
    setOpenModal(true);
  };

  const handleCancel = () => {
    setSelectedProperty(null);
    setOpenModal(false);
  };

  useEffect(() => {
    if (params) {
      const pms_status = params.get('pms_account_link');
      if (pms_status) {
        setOpenModal(true);
      }
      if (pms_status === 'error') {
        modalRef.current?.setFormPMS();
        modalRef.current?.setIsPMSLinked(false);
        modalRef.current?.setPMSError('Error linking PMS account');
      }
      if (pms_status === 'success') {
        modalRef.current?.setFormPMS();
        modalRef.current?.setIsPMSLinked(true);
      }
    }
  }, [params, modalRef.current]);

  return (
    <PropertiesWrap>
      <SearchWrapper>
        <SearchInput
          value={searchValue}
          onChange={({ target }: { target: { value: string } }) => {
            setSearchValue(target.value);
          }}
        />
        <span />
        <span />
      </SearchWrapper>
      <PropertiesSection>
        <PropertiesList>
          <AddButton onClick={() => setOpenModal(true)} />
          {!isLoading ? (
            propsList && propsList?.length ? (
              propsList?.map((prop, i) => (
                <SingleProperty
                  key={i}
                  id={prop.id}
                  name={prop.name}
                  image={prop.image}
                  handleDelete={() => handleDelete(prop.id)}
                  handleEdit={() => handleEdit(prop)}
                />
              ))
            ) : null
          ) : (
            <LoaderWrapper>
              <CircularProgress color="inherit" />
            </LoaderWrapper>
          )}
        </PropertiesList>
        <PaginationLinks>
          {totalPages > 1 && (
            <>
              <PageButton
                onClick={() =>
                  setPage((prev) => Math.max(1, prev - 1))
                }
                hidden={page === 1}
              >
                « Prev
              </PageButton>
              {renderPaginationButtons(page, totalPages).map(
                (pageNumber, index) => (
                  <PageButton
                    onClick={() => setPage(pageNumber)}
                    isPage
                    active={page === pageNumber}
                    disabled={pageNumber === page}
                  >
                    {pageNumber}
                  </PageButton>
                )
              )}
              <PageButton
                onClick={() =>
                  setPage((prev) => Math.min(totalPages, prev + 1))
                }
                hidden={page === totalPages}
              >
                Next »
              </PageButton>
            </>
          )}
        </PaginationLinks>
      </PropertiesSection>
      <BasicModal open={openModal} onClose={() => handleCancel()}>
        <AddNewPropertyModal
          onClose={handleCancel}
          property={selectedProperty}
          onAddProperty={(newProperty) => {
            setPropsList((prev) => {
              return prev ? [...prev, newProperty] : [newProperty];
            });
          }}
          onEditProperty={(prop) => {
            setPropsList((prev) => {
              return (
                prev?.map((p) =>
                  p.id === prop.id
                    ? {
                        ...p,
                        name: prop.name || '',
                        location: prop.location || '',
                        image: prop.image || null,
                      }
                    : p
                ) || []
              );
            });
          }}
          ref={modalRef}
        />
      </BasicModal>
    </PropertiesWrap>
  );
};

const PropertiesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-x: scroll;
`;

const PaginationLinks = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 10px;
  margin: 40px 30px 30px 0;

  a {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    color: #1976d2;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 8px 12px;
    transition: 0.2s linear;

    &:hover {
      border: 1px solid #1976d2;
    }

    &.active {
      background: #1976d2;
      color: #fff;
      border: 1px solid #1976d2;
    }
  }
`;

const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

const PropertiesWrap = styled.div`
  flex-direction: column;
  //gap: 30px;
  background: white;
  padding: 25px 0 0 25px;
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-rows: 85px 1fr;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-column: span 2;
  color: #1982fc;
`;

const PropertiesList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  padding-right: 25px;
`;

const PageButton = styled.button<{
  isPage?: boolean;
  active?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}>`
  display: inline-flex;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  -webkit-box-align: center;
  align-items: center;
  color: ${({ active }) => (active ? '#fff' : '#1976d2')};
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 8px 12px;
  transition: 0.2s linear;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  background: ${({ active }) => (active ? '#1976d2' : 'transparent')};
  border: 1px solid
    ${({ active }) => (active ? '#1976d2' : 'transparent')};

  &:hover {
    border: 1px solid #1976d2;
  }
`;

export default Properties;
