import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DeleteOutlined, EditNote } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Guest } from "../../types";
import moment from "moment";
import { BasicModal } from "../../components/BasicModal";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  first_name: yup
    .string()
    .min(2, "First Name must be at least 2 characters")
    .max(50, "First Name cannot exceed 50 characters")
    .required("First name is required"),
  last_name: yup
    .string()
    .min(2, "Last Name must be at least 2 characters")
    .max(50, "Last Name cannot exceed 50 characters")
    .required("Last name is required"),
  checkin_date: yup.string().required("Check in date is required"),
  checkin_time: yup.string().required("Check in time is required"),
  checkout_date: yup.string().required("Check out date is required"),
  checkout_time: yup.string().required("Check out time is required"),
});

function GuestList() {
  const propId = useParams().id;
  const [guestList, setGuestList] = useState<Guest[]>([]);
  const [currentGuest, setCurrentGuest] = useState<Guest | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    function fetchGuests() {
      axios.get(`/api/properties/${propId}/guests`).then((response) => {
        const futureGuests = response.data
          .map((guest: Guest) => {
            if (moment().isBetween(guest.date_checkin, guest.date_checkout)) {
              setCurrentGuest(guest);
              return;
            }
            return guest;
          })
          .filter(Boolean);
        setGuestList(futureGuests);
      });
    }

    fetchGuests();
  }, []);

  const processGuestDelete = (id: string) => {
    axios.delete(`/api/properties/${propId}/guests/${id}`).then(() => {
      setGuestList(guestList.filter((g) => g.id !== id));
    });
  };

  const processGuestAdd = (guest: Guest) => {
    axios.post(`/api/properties/${propId}/guests`, guest).then((response) => {
      setGuestList([...guestList, { ...guest, id: response.data.id }]);
    });
  };

  const processGuestEdit = (guest: Guest) => {
    axios
      .put(`/api/properties/${propId}/guests/${guest.id}`, guest)
      .then(() => {
        setGuestList(guestList.map((g) => (g.id === guest.id ? guest : g)));
      });
  };

  const { values, errors, setValues, resetForm, handleChange, submitForm } =
    useFormik({
      initialValues: {
        checkin_date: "",
        checkin_time: "",
        checkout_date: "",
        checkout_time: "",
        first_name: "",
        last_name: "",
        id: "",
      },
      validationSchema: validationSchema,
      validateOnChange: false,
      onSubmit: () => {
        setIsOpen(false);
        if (editMode) {
          processGuestEdit({
            id: values.id,
            first_name: values.first_name,
            last_name: values.last_name,
            date_checkin: moment(
              `${values.checkin_date} ${values.checkin_time}`,
            ).format("YYYY-MM-DDTHH:mm:ssZ"),
            date_checkout: moment(
              `${values.checkout_date} ${values.checkout_time}`,
            ).format("YYYY-MM-DDTHH:mm:ssZ"),
          });
          setEditMode(false);
        } else {
          processGuestAdd({
            first_name: values.first_name,
            last_name: values.last_name,
            date_checkin: moment(
              `${values.checkin_date} ${values.checkin_time}`,
            ).format("YYYY-MM-DDTHH:mm:ssZ"),
            date_checkout: moment(
              `${values.checkout_date} ${values.checkout_time}`,
            ).format("YYYY-MM-DDTHH:mm:ssZ"),
          });
        }
        resetForm();
      },
    });

  const onEdit = (guest: Guest) => {
    setIsOpen(true);
    setEditMode(true);
    setValues({
      first_name: guest.first_name,
      last_name: guest.last_name,
      checkin_date: moment(guest.date_checkin).format("YYYY-MM-DD"),
      checkin_time: moment(guest.date_checkin).format("HH:mm"),
      checkout_date: moment(guest.date_checkout).format("YYYY-MM-DD"),
      checkout_time: moment(guest.date_checkout).format("HH:mm"),
      id: guest.id || "",
    });
  };

  const onCancel = () => {
    setIsOpen(false);
    setEditMode(false);
    resetForm();
  };

  return (
    <GuestListWrapper>
      <GuestListContainer>
        <GuestListTitle>
          <span />
          Current Guest
        </GuestListTitle>
        <GuestListTable>
          <GuestListTableHeader>
            <span>Guest Name</span>
            <span>Check In Date</span>
            <span>Check Out Date</span>
            <span>Start Time</span>
            <span>End Time</span>
            <span>Actions</span>
          </GuestListTableHeader>
          {currentGuest ? (
            <GuestListRow>
              <span>{`${currentGuest?.first_name} ${currentGuest?.last_name}`}</span>
              <span>
                {moment(currentGuest?.date_checkin).format("MM/DD/YYYY")}
              </span>
              <span>
                {moment(currentGuest?.date_checkout).format("MM/DD/YYYY")}
              </span>
              <span>{moment(currentGuest?.date_checkin).format("HH:mm")}</span>
              <span>{moment(currentGuest?.date_checkout).format("HH:mm")}</span>
              <span>
                <Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    processGuestDelete(currentGuest.id || "");
                  }}
                  className="icon"
                >
                  <DeleteOutlined fontSize={"small"} />
                </Icon>
                <Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(currentGuest);
                  }}
                  className="icon"
                >
                  <EditNote fontSize={"small"} />
                </Icon>
              </span>
            </GuestListRow>
          ) : (
            <NoGuest>NO GUESTS TODAY</NoGuest>
          )}
        </GuestListTable>
      </GuestListContainer>

      <GuestListContainer>
        <GuestListTitle>
          Upcoming Guest List
          <GuestListButton onClick={() => setIsOpen(true)}>
            Add Guest
          </GuestListButton>
        </GuestListTitle>
        <GuestListTable>
          <GuestListTableHeader>
            <span>Guest Name</span>
            <span>Check In Date</span>
            <span>Check Out Date</span>
            <span>Start Time</span>
            <span>End Time</span>
            <span>Actions</span>
          </GuestListTableHeader>
          {guestList.map((guest, i) => (
            <GuestListRow even={i % 2 !== 0} key={i}>
              <span>{`${guest?.first_name} ${guest?.last_name}`}</span>
              <span>{moment(guest?.date_checkin).format("MM/DD/YYYY")}</span>
              <span>{moment(guest?.date_checkout).format("MM/DD/YYYY")}</span>
              <span>{moment(guest?.date_checkin).format("HH:mm")}</span>
              <span>{moment(guest?.date_checkout).format("HH:mm")}</span>
              <span>
                <Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    processGuestDelete(guest.id || "");
                  }}
                  className="icon"
                >
                  <DeleteOutlined fontSize={"small"} />
                </Icon>
                <Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(guest);
                  }}
                  className="icon"
                >
                  <EditNote fontSize={"small"} />
                </Icon>
              </span>
            </GuestListRow>
          ))}
        </GuestListTable>
      </GuestListContainer>

      <BasicModal open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContainer>
          <ModalTitle>Add Guest</ModalTitle>
          <InputContainer>
            <DatetimeRow>
              <InputWrapper>
                <Label>First Name</Label>
                <TextFieldStyled
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                />
                <FieldError error={errors.first_name}>
                  {errors.first_name}
                </FieldError>
              </InputWrapper>
              <InputWrapper>
                <Label>Last Name</Label>
                <TextFieldStyled
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                />
                <FieldError error={errors.last_name}>
                  {errors.last_name}
                </FieldError>
              </InputWrapper>
            </DatetimeRow>
            <DatetimeRow>
              <InputWrapper>
                <Label>Check In Date</Label>
                <DateTimeInput
                  type="date"
                  placeholder="Enter date"
                  value={values.checkin_date}
                  onChange={handleChange}
                  min={moment().format("YYYY-MM-DD")}
                  name="checkin_date"
                />
                <FieldError error={errors.checkin_date}>
                  {errors.checkin_date}
                </FieldError>
              </InputWrapper>
              <InputWrapper>
                <Label>Check In Time</Label>
                <DateTimeInput
                  type="time"
                  placeholder="Enter time"
                  value={values.checkin_time}
                  onChange={handleChange}
                  min={
                    moment(values.checkin_date).isSame(moment(), "day")
                      ? moment().format("HH:mm")
                      : undefined
                  }
                  disabled={!values.checkin_date}
                  name="checkin_time"
                />
                <FieldError error={errors.checkin_time}>
                  {errors.checkin_time}
                </FieldError>
              </InputWrapper>
            </DatetimeRow>
            <DatetimeRow>
              <InputWrapper>
                <Label>Check Out Date</Label>
                <DateTimeInput
                  type="date"
                  placeholder="Enter date"
                  value={values.checkout_date}
                  onChange={handleChange}
                  min={moment().format("YYYY-MM-DD")}
                  name="checkout_date"
                />
                <FieldError error={errors.checkout_date}>
                  {errors.checkout_date}
                </FieldError>
              </InputWrapper>
              <InputWrapper>
                <Label>Check Out Time</Label>
                <DateTimeInput
                  type="time"
                  placeholder="Enter time"
                  value={values.checkout_time}
                  onChange={handleChange}
                  min={
                    moment(values.checkout_date).isSame(moment(), "day")
                      ? moment().format("HH:mm")
                      : undefined
                  }
                  disabled={!values.checkout_date}
                  name="checkout_time"
                />
                <FieldError error={errors.checkout_time}>
                  {errors.checkout_time}
                </FieldError>
              </InputWrapper>
            </DatetimeRow>
          </InputContainer>
          <ButtonContainer>
            <Button onClick={() => onCancel()}>Cancel </Button>
            <Button onClick={submitForm} primary>
              {editMode ? "Submit" : "Add"}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </BasicModal>
    </GuestListWrapper>
  );
}

const GuestListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const GuestListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
`;

const GuestListTitle = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #64748b;
  padding: 0 8px 15px 8px;
  margin-bottom: 15px;

  & > span {
    display: block;
    width: 14px;
    height: 14px;
    background-color: #00dc54;
    border-radius: 14px;
  }
`;
const GuestListButton = styled.div`
  border-radius: 5px;
  background-color: #1982fc;
  padding: 6px 20px;
  color: white;
  margin-left: auto;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
`;
const GuestListTable = styled.div``;
const GuestListTableHeader = styled.div`
  background-color: #1982fc;
  padding: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  border-radius: 5px;

  & > span {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: white;
  }
`;
const GuestListRow = styled.div<{ even?: boolean }>`
  background-color: ${(props) => (props.even ? "white" : "#F1F5F9")};
  padding: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  border-radius: 5px;

  & > span {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #3a3b3c;
  }
`;

const Icon = styled.span`
  cursor: pointer;
  max-height: 24px;
  right: 20px;
  margin-right: 10px;
  color: #3a3b3c;
`;

const NoGuest = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #979797;
  padding: 20px;
`;

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  padding: 0 20px;
  width: 650px;
`;

const ModalTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 30px;
  margin-right: auto;
  margin-bottom: 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DatetimeRow = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: space-evenly;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 15px;
`;
const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const FieldError = styled.div<{ error?: string }>`
  display: ${(props) => (props?.error ? "block" : "none")};
  color: #ff8b8b;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
`;

const DateTimeInput = styled.input`
  flex: 1;
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  max-height: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::-webkit-datetime-edit {
    flex: unset;
    padding-right: 15px;
  }

  &::-webkit-calendar-picker-indicator {
    box-sizing: content-box;
    width: 30px;
    height: 30px;
    filter: opacity(0.5);
    cursor: pointer;
    //text-align: right;
    background-position: right;
    flex: 1;
  }
`;

export default GuestList;
