import styled from "@emotion/styled";
import { FC, useEffect, useState } from "react";
import { WelcomeScreenTitleProps } from "./types";
import { getDateByTimezone } from "../../utils/getDateByTimezone";
import { formatDateByTimezone } from "../../utils/formatDateByTimezone";

export const WelcomeScreenTitle: FC<WelcomeScreenTitleProps> = ({
  fromDate,
  toDate,
  timezone,
  useWhiteFont,
}) => {
  if (!timezone) {
    return (
      <NoTimezoneDateWrapper>
        <TimezoneProblemText>
          We’re having trouble detecting your timezone. <br />
          Check your device settings or try again later.
        </TimezoneProblemText>
      </NoTimezoneDateWrapper>
    );
  }

  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  const { timeString, dateString } = getDateByTimezone(timezone, currentTime);

  const formattedFromDate = fromDate
    ? formatDateByTimezone(timezone, new Date(fromDate), "MMM d, EEEE h:mma")
    : "N/A";
  const formattedToDate = toDate
    ? formatDateByTimezone(timezone, new Date(toDate), "MMM d, EEEE h:mma")
    : "N/A";

  useEffect(() => {
    const updateTime = () => {
      const newCurrentTime = new Date();

      setCurrentTime(newCurrentTime);
    };

    updateTime();

    // this needed to update time every minute
    const intervalId = setInterval(updateTime, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <TitleInfoWrapper useWhiteFont={useWhiteFont}>
      <TitleDateWrapper>
        <StyledHourTitle>{timeString}</StyledHourTitle>
        <StyledDateTitle>{dateString}</StyledDateTitle>
      </TitleDateWrapper>

      <TitleFromToDateWrapper>
        <StyledFromToTypography>
          <span className="from-to-label">From: </span>
          <span className="from-to-date">{formattedFromDate}</span>
        </StyledFromToTypography>

        <StyledFromToTypography>
          <span className="from-to-label">To: </span>
          <span className="from-to-date">{formattedToDate}</span>
        </StyledFromToTypography>
      </TitleFromToDateWrapper>
    </TitleInfoWrapper>
  );
};

const TitleInfoWrapper = styled.div<{ useWhiteFont?: boolean }>`
  label: title-info-wrapper;
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  gap: 20px;
  color: ${({ useWhiteFont }) => (useWhiteFont ? "#fff" : "#000")};
`;

const TitleDateWrapper = styled.div`
  label: title-date-wrapper;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleFromToDateWrapper = styled.div`
  label: title-from-to-date-wrapper;
  display: flex;
  flex-direction: row;
  gap: 90px;
`;

const NoTimezoneDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(74, 73, 73, 0.75);
  padding: 20px 40px;
  border-radius: 10px;
`;

const StyledHourTitle = styled.div`
  label: hour-title;
  font-family: Inter, sans-serif;
  font-size: 66px;
  line-height: 80px;

  /* For HD (1280x720) TVs */
  @media (min-width: 1280px) and (max-width: 1919px) {
    font-size: 49.5px; /* 66 * 0.75 */
    line-height: 60px; /* 80 * 0.75 */
  }

  /* For Full HD (1920x1080) TVs */
  @media (min-width: 1920px) and (max-width: 2559px) {
    font-size: 66px;
    line-height: 80px;
  }

  /* For 2K (2560x1440) TVs */
  @media (min-width: 2560px) and (max-width: 3839px) {
    font-size: 99px; /* 66 * 1.5 */
    line-height: 120px; /* 80 * 1.5 */
  }

  /* For 4K Ultra HD (3840x2160) TVs */
  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 132px; /* 66 * 2 */
    line-height: 160px; /* 80 * 2 */
  }

  /* For 8K Ultra HD (7680x4320) TVs */
  @media (min-width: 7680px) {
    font-size: 264px; /* 66 * 4 */
    line-height: 320px; /* 80 * 4 */
  }
`;

const StyledDateTitle = styled.div`
  label: date-title;
  font-family: Inter, sans-serif;
  font-size: 36px;
  line-height: 44px;

  /* For HD (1280x720) TVs */
  @media (min-width: 1280px) and (max-width: 1919px) {
    font-size: 27px; /* 36 * 0.75 */
    line-height: 33px; /* 44 * 0.75 */
  }

  /* For Full HD (1920x1080) TVs */
  @media (min-width: 1920px) and (max-width: 2559px) {
    font-size: 36px;
    line-height: 44px;
  }

  /* For 2K (2560x1440) TVs */
  @media (min-width: 2560px) and (max-width: 3839px) {
    font-size: 54px; /* 36 * 1.5 */
    line-height: 66px; /* 44 * 1.5 */
  }

  /* For 4K Ultra HD (3840x2160) TVs */
  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 72px; /* 36 * 2 */
    line-height: 88px; /* 44 * 2 */
  }

  /* For 8K Ultra HD (7680x4320) TVs */
  @media (min-width: 7680px) {
    font-size: 144px; /* 36 * 4 */
    line-height: 176px; /* 44 * 4 */
  }
`;

const StyledFromToTypography = styled.div`
  label: from-to-typography;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Inter, sans-serif;

  .from-to-label {
    font-size: 20px;
    font-weight: 600;

    /* For HD (1280x720) TVs */
    @media (min-width: 1280px) and (max-width: 1919px) {
      font-size: 15px; /* 20 * 0.75 */
    }

    /* For Full HD (1920x1080) TVs */
    @media (min-width: 1920px) and (max-width: 2559px) {
      font-size: 20px;
    }

    /* For 2K (2560x1440) TVs */
    @media (min-width: 2560px) and (max-width: 3839px) {
      font-size: 30px; /* 20 * 1.5 */
    }

    /* For 4K Ultra HD (3840x2160) TVs */
    @media (min-width: 3840px) and (max-width: 7679px) {
      font-size: 40px; /* 20 * 2 */
    }

    /* For 8K Ultra HD (7680x4320) TVs */
    @media (min-width: 7680px) {
      font-size: 80px; /* 20 * 4 */
    }
  }

  .from-to-date {
    font-size: 18px;
    font-weight: 600;

    /* For HD (1280x720) TVs */
    @media (min-width: 1280px) and (max-width: 1919px) {
      font-size: 13.5px; /* 18 * 0.75 */
    }

    /* For Full HD (1920x1080) TVs */
    @media (min-width: 1920px) and (max-width: 2559px) {
      font-size: 18px;
    }

    /* For 2K (2560x1440) TVs */
    @media (min-width: 2560px) and (max-width: 3839px) {
      font-size: 27px; /* 18 * 1.5 */
    }

    /* For 4K Ultra HD (3840x2160) TVs */
    @media (min-width: 3840px) and (max-width: 7679px) {
      font-size: 36px; /* 18 * 2 */
    }

    /* For 8K Ultra HD (7680x4320) TVs */
    @media (min-width: 7680px) {
      font-size: 72px; /* 18 * 4 */
    }
  }
`;

const TimezoneProblemText = styled.div`
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 34px;

  /* For HD (1280x720) TVs */
  @media (min-width: 1280px) and (max-width: 1919px) {
    font-size: 25.5px; /* 34 * 0.75 */
  }

  /* For Full HD (1920x1080) TVs */
  @media (min-width: 1920px) and (max-width: 2559px) {
    font-size: 34px;
  }

  /* For 2K (2560x1440) TVs */
  @media (min-width: 2560px) and (max-width: 3839px) {
    font-size: 51px; /* 34 * 1.5 */
  }

  /* For 4K Ultra HD (3840x2160) TVs */
  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 68px; /* 34 * 2 */
  }

  /* For 8K Ultra HD (7680x4320) TVs */
  @media (min-width: 7680px) {
    font-size: 136px; /* 34 * 4 */
  }
`;
