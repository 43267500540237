import React from "react";
import styled from "@emotion/styled";

function Column(props: { children: React.ReactNode; gap?: number }) {
  return <Container {...props}>{props.children}</Container>;
}

const Container = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.gap || 20}px;
`;

export default Column;
