import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { AttachFile } from "@mui/icons-material";
import axios from "axios";
import { QRCodeType } from "../../types";

type QRCodeBlockType = {
  value: string;
  onQRUpload: (qr: QRCodeType) => void;
};

function QrCodeBlock({ value, onQRUpload }: QRCodeBlockType) {
  const [qrFile, setQrFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setQrFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (qrFile) {
      const formData = new FormData();
      formData.append("file", qrFile);
      formData.append("type", "qr");

      axios
        .post("/api/upload", formData, {
          headers: {
            "X-CSRF-TOKEN":
              document
                ?.querySelector('meta[name="csrf-token"]')
                ?.getAttribute("content") || "",
          },
        })
        .then((response) => {
          setQrFile(null);
          onQRUpload(response.data);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setQrFile(null);
        });
    }
  }, [qrFile]);

  return (
    <div>
      <Title>QR Code</Title>
      <Row>
        <TextFieldStyled htmlFor={"qr-upload"}>
          {value || "Insert QR Code"}
        </TextFieldStyled>
        <AttachIcon>
          <AttachFile />
        </AttachIcon>
        <input
          style={{ display: "none" }}
          id={"qr-upload"}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
      </Row>
    </div>
  );
}

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
  position: relative;
  align-items: center;
`;

const TextFieldStyled = styled.label`
  width: 100%;
  border-radius: 10px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 2px 10px;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  cursor: pointer;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const AttachIcon = styled.div`
  position: absolute;
  right: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #3a3b3c;
`;

export default QrCodeBlock;
