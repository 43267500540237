import React from "react";
import styled from "@emotion/styled";

type ContactsBlockType = {
    contact_email?: string;
    contact_phone?: string;
    onEmailChange: (str: string) => void;
    onPhoneChange: (str: string) => void;
};

function ContactsBlock({
    contact_email,
    contact_phone,
    onEmailChange,
    onPhoneChange,
}: ContactsBlockType) {
    return (
        <div>
            <Title>Contact Information</Title>
            <Row>
                <TextFieldStyled
                    placeholder="000-000-0000"
                    value={contact_phone || ""}
                    onChange={(e) => onPhoneChange(e.target.value)}
                />
                <TextFieldStyled
                    placeholder="Email@gmail.com"
                    value={contact_email || ""}
                    onChange={(e) => onEmailChange(e.target.value)}
                />
            </Row>
        </div>
    );
}

export default ContactsBlock;

const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
`;

const TextFieldStyled = styled.input`
    width: 100%;
    border-radius: 10px;
    color: black;
    border: 1px solid #a8a6a6;
    padding: 2px 10px;
    font-size: 14px;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    margin-bottom: 15px;

    &::placeholder {
        color: #a8a6a6;
    }
`;
