import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { PlayImage } from "./PlayImage";

export type SliderComponentProps = {
  images: string[];
  isFocused: boolean;
  isPlayingSlider: boolean;
  setIsPlayingSlider: Dispatch<SetStateAction<boolean>>;
};

const ImageSlider = ({
  images,
  isFocused,
  isPlayingSlider,
  setIsPlayingSlider,
}: SliderComponentProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (isPlayingSlider) {
      intervalRef.current = window.setInterval(() => {
        nextImage();
      }, 5000);
    }
    return () =>
      intervalRef.current ? clearInterval(intervalRef.current) : undefined;
  }, [isPlayingSlider, currentIndex]);

  const nextImage = () => {
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 500); // Wait for the fade-out transition before actually changing the image
  };

  const changeDot = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    e.stopPropagation();
    setCurrentIndex(index);
  };

  if (!images.length) {
    return (
      <NoSlidesWrapper>
        <NoSlidesTitle>No provided slides</NoSlidesTitle>
      </NoSlidesWrapper>
    );
  }

  return (
    <SliderContainer
      isFocused={isFocused}
      onClick={() => setIsPlayingSlider(!isPlayingSlider)}
    >
      {images.map((src, index) => (
        <ImageWrapper key={index} isVisible={index === currentIndex}>
          <Image src={src} alt={`slide-${index}`} />
        </ImageWrapper>
      ))}
      {!isPlayingSlider && (
        <ControlButton>
          <PlayImage />
        </ControlButton>
      )}
      <DotsContainer>
        {images.map((_, index) => (
          <Dot
            key={index}
            isActive={index === currentIndex}
            onClick={(e) => changeDot(e, index)}
          />
        ))}
      </DotsContainer>
    </SliderContainer>
  );
};

const NoSlidesWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(74, 73, 73, 0.75);
  box-shadow: 0 4px 4px 0 #00000040;
`;

const NoSlidesTitle = styled.p`
  font-size: 48px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #fff;
  margin: 0;

  @media (max-width: 1800px) {
    font-size: 38px;
  }
`;

const SliderContainer = styled.div<{ isFocused: boolean }>`
  border: ${({ isFocused }) => (isFocused ? "5px solid #1982FC" : "none")};
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ControlButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
`;

const DotsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 18px;
`;

const Dot = styled.div<{ isActive: boolean }>`
  width: 14px;
  height: 14px;
  background-color: ${({ isActive }) => (isActive ? "#1982FC" : "#fff")};
  border-radius: 50%;
`;

const ImageWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

export default ImageSlider;
