import React, { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";

type Props = {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchInput = ({ value, onChange }: Props) => {
    return (
        <TextFieldStyled
            label="Search Properties..."
            id="outlined-start-adornment"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <Search fontSize="small" />
                    </InputAdornment>
                ),
            }}
            sx={{
                "&.MuiFormLabel-root": {
                    fontSize: "14px",
                },
            }}
            value={value}
            onChange={onChange}
        />
    );
};

const TextFieldStyled = styled(TextField)`
    color: #64748b;

    input {
        width: 274px;
    }
`;
