import React, { forwardRef } from "react";
import styled from "@emotion/styled";

type Props = {
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  src: string | null | undefined;
  borderRadius?: number | string;
  isFocused?: boolean;
};

const VideoPlayer = forwardRef<HTMLIFrameElement, Props>(
  (
    {
      autoPlay = false,
      loop = false,
      muted = false,
      src,
      borderRadius = 0,
      isFocused = false,
    },
    ref,
  ) => {
    const convertToEmbedURL = (videoURL: string | undefined | null) => {
      if (!videoURL) return undefined;

      const youtubeMatch = videoURL?.match(
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/,
      );
      return youtubeMatch
        ? `https://www.youtube.com/embed/${youtubeMatch[1]}`
        : videoURL;
    };

    const embedURL = convertToEmbedURL(src);

    return (
      <VideoWrapper isFocused={isFocused}>
        {embedURL && (
          <iframe
            ref={ref}
            src={`${embedURL}?autoplay=${autoPlay ? 1 : 0}&loop=${loop ? 1 : 0}&mute=${muted ? 1 : 0}&enablejsapi=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            tabIndex={0}
            style={{ borderRadius, overflow: "hidden" }}
          />
        )}
      </VideoWrapper>
    );
  },
);

const VideoWrapper = styled.div<{ isFocused?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  outline: ${({ isFocused }) => (isFocused ? "3px solid blue" : "none")};

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export default VideoPlayer;
