export default function renderPaginationButtons(
  currentPage: number,
  totalPages: number,
) {
  if (totalPages < 1 || currentPage < 1 || currentPage > totalPages) {
    return [];
  }

  let pages = [];

  if (totalPages <= 3) {
    pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    if (currentPage === 1) {
      pages = [1, 2, 3];
    } else if (currentPage === totalPages) {
      pages = [totalPages - 2, totalPages - 1, totalPages];
    } else {
      pages = [currentPage - 1, currentPage, currentPage + 1];
    }
  }

  return pages;
}
