import React from "react";
import { Route, Routes } from "react-router-dom";
import WelcomeScreen from "./pages/WelcomeScreen/WelcomeScreen";
import SignUp from "./pages/Auth/SignUp";
import LogIn from "./pages/Auth/LogIn";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Onboarding from "./pages/Auth/Onboarding";
import { MainLayout } from "./layouts/MainLayout/MainLayout";
import Properties from "./pages/Properties/Properties";
import DetailsLayout from "./layouts/DetailsLayout/DetailsLayout";
import PropertyDetails from "./pages/PropertyDetails/PropertyDetails";
import Guests from "./pages/Guests/Guests";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import VerifyEmail from "./pages/Auth/VerifyEmail";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<LogIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signup/verify-email" element={<VerifyEmail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/onboarding"
        element={
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<Properties />} />
        <Route path={'/properties'} element={<Properties />} />
        <Route path={'/properties/:id'} element={<DetailsLayout />}>
          <Route
            path="/properties/:id/edit"
            element={<PropertyDetails />}
          />
          <Route path="/properties/:id/guests" element={<Guests />} />
        </Route>
      </Route>

      <Route
        path="welcome-screen/:tokenId"
        element={<WelcomeScreen />}
      />
      <Route
        path="welcome-screen/preview/:propertyId"
        element={<WelcomeScreen />}
      />
    </Routes>
  );
}

export default App;
