import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { AttachFile } from "@mui/icons-material";
import { BasicModal } from "../../components/BasicModal";
import axios from "axios";
import { ImageType } from "../../types";

type BackgroundBlockType = {
  value?: string;
  onBGSelect: (image: ImageType) => void;
};

function BackgroundBlock({ value, onBGSelect }: BackgroundBlockType) {
  const [modalOpen, setModalOpen] = useState(false);
  const [preloadedImages, setPreloadedImages] = useState<ImageType[]>([]);
  const [selectedImage, setSelectedImage] = useState<ImageType | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile);
      formData.append("type", "background");

      axios
        .post("/api/upload", formData, {
          headers: {
            "X-CSRF-TOKEN":
              document
                ?.querySelector('meta[name="csrf-token"]')
                ?.getAttribute("content") || "",
          },
        })
        .then((response) => {
          setImageFile(null);
          setSelectedImage(response.data);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setImageFile(null);
          setSelectedImage(null);
        });
    }
  }, [imageFile]);

  useEffect(() => {
    const loadImages = () => {
      axios.get("/api/default-backgrounds").then((res) => {
        setPreloadedImages(res.data);
      });
    };
    loadImages();
  }, []);

  const onCancel = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const onSubmit = () => {
    if (selectedImage) {
      onBGSelect(selectedImage);
      setModalOpen(false);
    }
  };

  return (
    <>
      <div>
        <Title>Background Image</Title>
        <Row>
          <ModalToggle onClick={() => setModalOpen(true)}>
            {value || "No background selected"}
          </ModalToggle>
          <AttachIcon>
            <AttachFile />
          </AttachIcon>
        </Row>
      </div>
      <BasicModal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContainer>
          <ModalTitle>Choose Background Image</ModalTitle>
          <ImagesContainer>
            <ImageCarousel>
              <ImageCarouselUploadButton htmlFor="fileUpload">
                <span>+</span>
                <input
                  type="file"
                  id="fileUpload"
                  onChange={handleFileChange}
                />
              </ImageCarouselUploadButton>
              {preloadedImages.map((image: ImageType) => (
                <ImageCarouselItemWrapper key={image.id}>
                  <ImageCarouselItem
                    src={image.url.thumb}
                    alt="background"
                    onClick={() => setSelectedImage(image)}
                  />
                </ImageCarouselItemWrapper>
              ))}
            </ImageCarousel>
            <ImagePreview>
              <ImageTitle>Current Background</ImageTitle>
              {selectedImage?.url?.medium ? (
                <ImagePreviewItem
                  src={selectedImage?.url?.medium}
                  alt={"Background"}
                />
              ) : value ? (
                <ImagePreviewItem src={value} alt={"Background"} />
              ) : (
                <ImageNone>Nothing Selected</ImageNone>
              )}
              <ImageDisclaimer>
                PNG, JPEG, JPG, HEIC 4000 x 3000 Pixel Recommendation*
              </ImageDisclaimer>
            </ImagePreview>
          </ImagesContainer>
          <ButtonContainer>
            <Button onClick={() => onCancel()}>Cancel </Button>
            <Button onClick={() => onSubmit()} primary>
              Select
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </BasicModal>
    </>
  );
}

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 6px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
  position: relative;
  align-items: center;
`;

const ModalToggle = styled.div`
  width: 100%;
  border-radius: 10px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 2px 10px;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  cursor: pointer;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const AttachIcon = styled.div`
  position: absolute;
  right: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #3a3b3c;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  padding: 0 20px;
`;

const ModalTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0;
  margin-right: auto;
`;

const ImagesContainer = styled.div``;
const ImageCarousel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: scroll;
  max-width: 530px;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ImageCarouselUploadButton = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 70px;
  flex: 1;
  background-color: #d9d9d9;
  cursor: pointer;

  & > span {
    color: white;
    width: 100px;
    text-align: center;
    font-size: 24px;
  }

  & > input {
    display: none;
  }
`;
const ImageCarouselItemWrapper = styled.div`
  width: 100px;
  height: 70px;
  cursor: pointer;
`;
const ImageCarouselItem = styled.img`
  width: 100px;
  height: 70px;
  object-fit: cover;
`;
const ImagePreview = styled.div`
  margin-top: 20px;
`;
const ImagePreviewItem = styled.img`
  width: 530px;
  height: 230px;
  object-fit: cover;
  border: 1px solid transparent;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`;
const ImageTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #707070;
`;
const ImageDisclaimer = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #eb5c6a;
`;

const ImageNone = styled.div`
  width: 530px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  text-align: center;
  line-height: 230px;
  border: 1px solid #d9d9d9;
  background-color: #f5f5f5;
  paddding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #707070;
`;

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;

export default BackgroundBlock;
