import React, { ChangeEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { LocationType } from "../../types";
import { ReducedProperty } from "./Properties";
import { AttachFile } from "@mui/icons-material";
import styled from "@emotion/styled";

type ManualFormProps = {
  onClose: () => void;
  onAddProperty: (property: ReducedProperty) => void;
  onEditProperty: (property: ReducedProperty) => void;
  property: ReducedProperty | null;
};

function ManualForm({
  property,
  onClose,
  onAddProperty,
  onEditProperty,
}: ManualFormProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [locationOptions, setLocationOptions] = useState<LocationType[]>([]);
  const [locationSelected, setLocationSelected] = useState(true);

  const formik = useFormik({
    initialValues: {
      name: property?.name || "",
      location: property?.place?.id || "",
      image: property?.image || null,
      locationInput: property?.place?.address || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      location: Yup.string().required("Location is required"),
    }),
    onSubmit: (values) => {
      if (!property?.id) {
        axios
          .post("/api/properties", {
            name: values.name,
            location: values.location,
            image_id: values.image?.id,
          })
          .then((res) => {
            onClose();
            onAddProperty({ id: res.data.id || "", ...values });
          });
      } else {
        axios
          .put(`/api/properties/${property?.id}`, {
            name: values.name,
            location: values.location,
            image_id: values.image?.id,
          })
          .then(() => {
            onClose();
            onEditProperty({ id: property?.id, ...values });
          });
      }
    },
  });

  useEffect(() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("type", "main");

      axios
        .post("/api/upload", formData, {
          headers: {
            "X-CSRF-TOKEN":
              document
                ?.querySelector('meta[name="csrf-token"]')
                ?.getAttribute("content") || "",
          },
        })
        .then((response) => {
          setSelectedFile(null);
          formik.setFieldValue("image", response.data);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setSelectedFile(null);
          formik.setFieldValue("image", null);
        });
    }
  }, [selectedFile]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (formik.values.locationInput && !locationSelected) {
        axios
          .get(`/api/find-location?input=${formik.values.locationInput}`)
          .then((response) => {
            setLocationOptions(response.data);
          })
          .catch((error) => {
            console.error("Error getting locations:", error);
            setLocationOptions([]);
          });
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [formik.values.locationInput]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  return (
    <>
      <InputContainer>
        <InputWrapper>
          <Label>Property Name</Label>
          <TextFieldStyled
            placeholder="Property Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <FieldError error={formik.errors.name}>
            {formik.errors.name}
          </FieldError>
        </InputWrapper>
        <InputWrapper>
          <Label>Location</Label>
          <DropdownWrapper>
            <TextFieldStyled
              placeholder="Location"
              value={formik.values.locationInput}
              name="locationInput"
              onChange={(e) => {
                setLocationSelected(false);
                formik.handleChange(e);
              }}
            />
            <Dropdown options={!!locationOptions.length}>
              {locationOptions.map((locationOption) => (
                <DropdownItem
                  key={locationOption.placeId}
                  onClick={() => {
                    formik.setFieldValue("location", locationOption.placeId);
                    formik.setFieldValue(
                      "locationInput",
                      locationOption.address,
                    );
                    setLocationSelected(true);
                    setLocationOptions([]);
                  }}
                >
                  {locationOption.address}
                </DropdownItem>
              ))}
            </Dropdown>
          </DropdownWrapper>
          <FieldError error={formik.errors.location}>
            {formik.errors.location}
          </FieldError>
        </InputWrapper>
        <InputWrapper>
          <Label>Property Image</Label>
          <Row>
            <ModalToggle htmlFor="propertyImage">
              {formik.values.image?.url?.medium || "No background selected"}
            </ModalToggle>
            <AttachIcon>
              <AttachFile />
            </AttachIcon>
            <FileInput
              type="file"
              id="propertyImage"
              onChange={handleFileChange}
            />
          </Row>
        </InputWrapper>
      </InputContainer>
      <ButtonContainer>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </ButtonContainer>
    </>
  );
}

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 30px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldError = styled.div<{ error?: string }>`
  display: ${(props) => (props?.error ? "block" : "none")};
  color: #ff8b8b;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
`;

const ModalToggle = styled.label`
  display: block;
  width: 100%;
  border-radius: 6px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
  position: relative;
  align-items: center;
`;

const FileInput = styled.input`
  display: none;
`;

const AttachIcon = styled.div`
  position: absolute;
  right: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #3a3b3c;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const Dropdown = styled.div<{ options: boolean }>`
  position: absolute;
  display: ${(props) => (props.options ? "block" : "none")};
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #a8a6a6;
  border-radius: 6px;
  z-index: 1;
  overflow: hidden;
`;
const DropdownItem = styled.div`
  padding: 5px 10px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export default ManualForm;
