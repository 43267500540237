import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { AddOutlined, AttachFile } from "@mui/icons-material";
import { BasicModal } from "../../components/BasicModal";
import ImageIcon from "../../components/icons/ImageIcon";
import axios from "axios";

type CarouselBlockProps = {
  onImageSubmit: (image: UploadedFile) => void;
  onImageRemove: (id: string) => void;
  videoUrl: string;
  setVideoUrl: (str: string) => void;
  useVideo: boolean;
  setUseVideo: (state: boolean) => void;
  gallery: UploadedFile[];
};

type UploadedFile = {
  id: string;
  url: {
    thumb: string;
    medium: string;
    original: string;
  };
};

function CarouselBlock({
  onImageSubmit,
  onImageRemove,
  videoUrl,
  setVideoUrl,
  useVideo,
  setUseVideo,
  gallery,
}: CarouselBlockProps) {
  const [selected, setSelected] = useState<File | null>(null);
  const [uploadedUrl, setUploadedUrl] = useState<UploadedFile | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelected(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (selected) {
      const formData = new FormData();
      formData.append("file", selected);
      formData.append("type", "gallery");

      axios
        .post("/api/upload", formData, {
          headers: {
            "X-CSRF-TOKEN":
              document
                ?.querySelector('meta[name="csrf-token"]')
                ?.getAttribute("content") || "",
          },
        })
        .then((response) => {
          setSelected(null);
          setUploadedUrl(response.data);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setSelected(null);
          setUploadedUrl(null);
        });
    }
  }, [selected]);

  const onAddImage = (image: UploadedFile) => {
    onImageSubmit(image);
    setModalOpen(false);
    setUploadedUrl(null);
    setSelected(null);
  };

  const onCancel = () => {
    setModalOpen(false);
    setUploadedUrl(null);
    setSelected(null);
  };

  return (
    <>
      <div>
        <Title>Carousel Image</Title>
        <Checkbox>
          <Check
            type="radio"
            name="carousel"
            id="carousel"
            checked={!useVideo}
            onChange={() => setUseVideo(false)}
          />
          <Label htmlFor="carousel">Load up to 6 images</Label>
        </Checkbox>
        <Carousel>
          {gallery?.map((image, index) => (
            <CarouselImageContainer key={index}>
              <CarouselImageWrapper>
                <Image src={image.url.thumb} alt="Carousel Image" />
              </CarouselImageWrapper>
              <CarouselRemove onClick={() => onImageRemove(image.id)}>
                &times;
              </CarouselRemove>
            </CarouselImageContainer>
          ))}
          {[...Array(6 - (gallery?.length || 0))].map((image, index) => (
            <div key={index}>
              <CarouselItem onClick={() => setModalOpen(true)}>
                <AddOutlined />
              </CarouselItem>
            </div>
          ))}
        </Carousel>
        <Or>OR</Or>
        <Checkbox>
          <Check
            type="radio"
            name="carousel"
            id="video"
            checked={useVideo}
            onChange={() => setUseVideo(true)}
          />
          <Label htmlFor="video">Load 1 Video</Label>
        </Checkbox>
        <Video>
          <TextFieldStyled
            placeholder="Insert Video or URL link"
            value={videoUrl}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setVideoUrl(e.target.value)
            }
          />
          <AttachIcon>
            <AttachFile />
          </AttachIcon>
        </Video>
      </div>
      <BasicModal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContainer>
          <ModalTitle>Insert Image</ModalTitle>
          {uploadedUrl ? (
            <ImageContainer>
              <Image src={uploadedUrl.url.medium} alt="Carousel Image" />
              <ImageReplace htmlFor="image">Replace</ImageReplace>
            </ImageContainer>
          ) : (
            <>
              <ImageLabel htmlFor="image">
                <ImageIcon stroke="#a8a6a6" />
                <ImageDisclaimer>
                  PNG, JPEG, JPG, HEI, 20 MB <span>*</span>
                </ImageDisclaimer>
              </ImageLabel>
            </>
          )}
          <ImageInput
            type="file"
            id="image"
            accept="image/*"
            onChange={handleFileChange}
          />
          <ButtonContainer>
            <Button onClick={() => onCancel()}>Cancel </Button>
            <Button
              onClick={() => uploadedUrl !== null && onAddImage(uploadedUrl)}
              primary
              disabled={!uploadedUrl}
            >
              Add
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </BasicModal>
    </>
  );
}

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 4px;
`;

const Checkbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
`;
const Check = styled.input`
  appearance: none;
  width: 10px;
  height: 10px;
  background: #d9d9d9;
  border: none;
  box-sizing: border-box;
  border-radius: 0;
  cursor: pointer;

  &:checked {
    background: #05de8f;
  }
`;
const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #a8a6a6;
  cursor: pointer;
`;

const Carousel = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 7px;
`;

const Or = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 9px 0 0;
  line-height: 24px;
  text-align: center;
`;

const CarouselItem = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px dashed #a8a6a6;
  color: #a8a6a6;
  cursor: pointer;
`;

const CarouselImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CarouselImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #000;
`;

const CarouselRemove = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  width: 10px;
  height: 10px;
  background: #fff;
  color: #000;
  transform: translate(50%, -50%);
  border-radius: 10px;
  border: 1px solid #000;
  cursor: pointer;
  font-size: 12px;
  line-height: 8px;
  text-align: center;
`;

const Video = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
  position: relative;
  align-items: center;
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 10px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 2px 10px;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const AttachIcon = styled.div`
  position: absolute;
  right: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #3a3b3c;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  padding: 0 20px;
`;

const ModalTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0;
  margin-right: auto;
`;

const ImageLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  //flex: 1;
  width: 515px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px dashed #a8a6a6;
  color: #a8a6a6;
  cursor: pointer;
  font-size: 100px;
`;

const ImageDisclaimer = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #a8a6a6;

  & > span {
    color: red;
  }
`;

const ImageInput = styled.input`
  display: none;
`;

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? "#1982FC" : "transparent")};
  border: 1px solid #1982fc;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "white" : "#1982FC")};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0d6efd" : "white")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 515px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageReplace = styled.label`
  padding: 3px 12px;
  border-radius: 10px;
  background: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  position: absolute;
  border: 1px solid #000;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

export default CarouselBlock;
