import React from "react";
import styled from "@emotion/styled";

type WelcomeMessageProps = {
  welcomeGreeting: string;
  welcomeMessage: string;
  onGreetingChange: (str: string) => void;
  onMessageChange: (str: string) => void;
};

export const WelcomeMessage = ({
  welcomeGreeting,
  welcomeMessage,
  onGreetingChange,
  onMessageChange,
}: WelcomeMessageProps) => {
  return (
    <div>
      <Title>Welcome message</Title>
      <Disclaimer>
        You can format the greeting by using $first and $last in it. Those
        values will be raplaced with guest name.
      </Disclaimer>
      <TextFieldStyled
        placeholder="Greeting"
        value={welcomeGreeting || ""}
        onChange={(e) => onGreetingChange(e.target.value.slice(0, 30))}
      />
      <Example>
        Example:{" "}
        {welcomeGreeting.replace("$first", "John").replace("$last", "Doe")}
      </Example>
      <TextAreaStyled
        placeholder="Message"
        value={welcomeMessage}
        rows={7}
        onChange={(e) => onMessageChange(e.target.value.slice(0, 255))}
      />
    </div>
  );
};

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
`;

const Disclaimer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
  padding-left: 5px;
`;

const Example = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  color: #a8a6a6;
  padding-left: 5px;
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 10px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  margin-bottom: 5px;

  &::placeholder {
    color: #a8a6a6;
  }
`;

const TextAreaStyled = styled.textarea`
  width: 100%;
  border-radius: 10px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  resize: none;

  &::placeholder {
    color: #a8a6a6;
  }
`;
