import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Row from "../components/Row";
import Column from "../components/Column";
import SocialButton from "../components/SocialButton";
import AuthInput from "../components/AuthInput";
import { useFormik } from "formik";
import Button from "../components/Button";
import * as yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const validationLoginSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
  password: yup.string().required("Required"),
});

function Index() {
  const navigate = useNavigate();
  const [googleRedirect, setGoogleRedirect] = useState("");
  const [facebookRedirect, setFacebookRedirect] = useState("");

  const { values, errors, setFieldError, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validateOnChange: false,
      validationSchema: validationLoginSchema,
      onSubmit: () => singIn(),
    });

  useEffect(() => {
    const getSocialRedirect = async () => {
      const google = await axios.get("/api/auth/google/redirect");
      const facebook = await axios.get("/api/auth/facebook/redirect");
      setGoogleRedirect(google.data.url);
      setFacebookRedirect(facebook.data.url);
    };
    getSocialRedirect();
  }, []);

  const singIn = async () => {
    axios
      .post("/api/auth/login", values)
      .then((res) => {
        Cookies.set("auth_token", res.data.token);
        navigate("/properties");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setFieldError("email", "Email is not verified");
        }
        if (err.response.status === 401) {
          setFieldError("password", "Invalid password");
        }
      });
  };

  return (
    <SigninWrapper>
      <FormWrapper>
        <FormHeader>
          <Logo>
            <img src="/logo-wide.png" alt="Logo" />
          </Logo>
          <SignupText>
            Don’t have an account?{" "}
            <SignupLink href="/signup">Sign up </SignupLink>
          </SignupText>
        </FormHeader>
        <FormContainer>
          <SigninForm>
            <Row>
              <FormTitle>Sign in to your account</FormTitle>
            </Row>
            <Row>
              <Column>
                <SocialButton href={googleRedirect}>
                  <SocialImage src="/google.svg" />
                  <p>Connect with Google</p>
                </SocialButton>
                <SocialButton href={facebookRedirect}>
                  <SocialImage src="/facebook.svg" />
                  <p>Connect with Facebook</p>
                </SocialButton>
              </Column>
            </Row>
            <Divider>
              <p>OR</p>
            </Divider>
            <Row>
              <AuthInput
                type="email"
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
              />
            </Row>
            <Row>
              <AuthInput
                type="password"
                name="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                error={errors.password}
              />
            </Row>
            <Row>
              <ForgotLink href="/forgot-password">
                Forgot your password?
              </ForgotLink>
            </Row>
            <ButtonContainer>
              <Button onClick={handleSubmit}>Sign in</Button>
            </ButtonContainer>
          </SigninForm>
        </FormContainer>
      </FormWrapper>
    </SigninWrapper>
  );
}

const SigninWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
  width: 100%;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const FormHeader = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    height: 40px;
    width: 100%;
    object-fit: contain;
  }

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
`;

const SignupText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  text-align: left;
  font-optical-sizing: auto;
  margin: 0;
  color: #5c5c5c;
`;

const SignupLink = styled.a`
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  appearance: none;
  color: #5c5c5c;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const FormTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
`;

const SigninForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 100%;
  justify-content: center;
  gap: 20px;
`;

const Divider = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  position: relative;
  color: #5c5c5c;

  p {
    position: relative;
    width: fit-content;
    padding: 0 10px;
    margin: 0 auto;
    background-color: white;
    z-index: 2;
  }

  &:before {
    content: "";
    width: 100%;
    border-bottom: solid 1px #eeeff0;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
`;

const ForgotLink = styled.a`
  font-family: Inter, sans-serif;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #5c5c5c;
  text-decoration: underline;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const SocialImage = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
`;

export default Index;
