import React from "react";
import styled from "@emotion/styled";

function SocialButton({
  children,
  href,
}: {
  children: React.ReactNode;
  href?: string;
}) {
  return <ButtonWrapper href={href}>{children}</ButtonWrapper>;
}

const ButtonWrapper = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 100px;
  background: #eeeff0;
  color: #000;
  padding: 14px 24px;

  p {
    margin: 0;
  }
`;

export default SocialButton;
