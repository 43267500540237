import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PMSProperty } from './AddNewPropertyModal';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { set } from 'js-cookie';

type PmsPropertyListProps = {
  propertiesList: PMSProperty[];
  onClose: () => void;
};

function PmsPropertyList({
  propertiesList,
  onClose,
}: PmsPropertyListProps) {
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const syncProperties = () => {
    axios
      .post('/api/pms/sync', { ids: selectedList })
      .then(() => {
        onClose();
        navigate(location.pathname, { replace: true });
        navigate(0);
      })
      .catch((err) => {
        setError('Error syncing properties');
      });
  };

  return (
    <PropertyList>
      <Disclaimer>
        Select the properties you want to activate
      </Disclaimer>
      <ListWrapper>
        {propertiesList.map((property) => (
          <PropertyItem
            key={property.id}
            active={selectedList.includes(property.id)}
            onClick={() => {
              if (selectedList.includes(property.id)) {
                setSelectedList((prev) =>
                  prev.filter((id) => id !== property.id)
                );
              } else {
                setSelectedList((prev) => [...prev, property.id]);
              }
            }}
          >
            <PropertyName>{property.name}</PropertyName>
            <Check active={selectedList.includes(property.id)}>
              {selectedList.includes(property.id) ? <Dot /> : null}
            </Check>
          </PropertyItem>
        ))}
      </ListWrapper>
      <PropertyCount>
        {selectedList.length
          ? `${selectedList.length} Properties Selected`
          : null}
      </PropertyCount>
      <SyncError>{error}</SyncError>
      <ButtonContainer>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          primary
          disabled={!selectedList.length && !error}
          onClick={syncProperties}
        >
          Continue
        </Button>
      </ButtonContainer>
    </PropertyList>
  );
}

const PropertyList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const PropertyItem = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 13px 15px;
  justify-content: space-between;
  border: 1px solid
    ${(props) => (props.active ? '#24A149' : '#A8A6A6')};
  border-radius: 6px;
  color: ${(props) => (props.active ? '#24A149' : '#000')};
`;

const PropertyName = styled.div`
  color: inherit;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const Check = styled.div<{ active?: boolean }>`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${(props) => (props.active ? '#24A149' : '#A8A6A6')};
`;

const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #24a149;
`;

const Disclaimer = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
`;

const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) =>
    props.primary ? '#1982FC' : 'transparent'};
  border: 1px solid #1982fc;
  border-radius: 30px;
  color: ${(props) => (props.primary ? 'white' : '#1982FC')};
  font-family: Roboto, sans-serif;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 14px 20px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.primary ? '#0d6efd' : 'white'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;

const PropertyCount = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #a8a6a6;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  max-height: 350px;
  margin-bottom: 20px;
`;

const SyncError = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: red;
`;

export default PmsPropertyList;
