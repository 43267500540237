import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const Loader = () => (
    <LoaderContainer>
        <Spinner />
    </LoaderContainer>
);

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
`;

const Spinner = styled.div`
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: ${spin} 2s linear infinite;

    @media (max-width: 1800px) {
        width: 96px;
        height: 96px;
    }
`;
