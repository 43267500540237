import React from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as yup from "yup";
import VerifyCodeForm from "./VerifyCodeForm";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const validationEmailSchema = yup.object().shape({
  verificationCode: yup.string(),
});

type FormValues = {
  verificationCode: string;
};

function VerifyEmail() {
  const navigate = useNavigate();
  const { email, first_name, last_name } = useLocation().state;

  const { values, errors, setFieldValue, setFieldError, handleSubmit } =
    useFormik({
      initialValues: {
        verificationCode: "",
      },
      validateOnChange: false,
      validationSchema: validationEmailSchema,
      onSubmit: (values) => verifyCode(values),
    });

  const verifyCode = (values: FormValues) => {
    if (values.verificationCode.length === 4) {
      axios
        .post("/api/auth/verify-email", {
          email: email,
          verify_code: values.verificationCode,
        })
        .then((response) => {
          Cookies.set("auth_token", response.data.token);
          navigate("/onboarding", {
            state: {
              first_name: first_name,
              last_name: last_name,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 500) {
            setFieldError("verificationCode", "Invalid verification code");
          } else if (error.response) {
            setFieldError(
              "verificationCode",
              "Internal server error, try again later",
            );
          }
        });
    } else {
      setFieldError("verificationCode", "Verification code must be 4 digits");
    }
  };

  const handleVerificationCodeChange = (value: string) => {
    setFieldValue("verificationCode", value);
  };

  return (
    <SignupWrapper>
      <FormHalf>
        <FormHeader>
          <Logo>
            <img src="/logo-wide.png" alt="Logo" />
          </Logo>
          <SigninText>
            Already have an account?{" "}
            <SigninLink href="/login">Sign in</SigninLink>
          </SigninText>
        </FormHeader>
        <VerifyCodeForm
          values={values}
          error={errors}
          email={email}
          handleChange={handleVerificationCodeChange}
          handleSubmit={handleSubmit}
        />
      </FormHalf>
      <ImageHalf>
        <BulletPoints>
          <BulletPoint>
            <BulletPointIcon src="/following.svg" />
            <BulletPointText>Personalized Guest Experience </BulletPointText>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src="/recommendations.svg" />
            <BulletPointText>Local Recommendations </BulletPointText>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src="/list.svg" />
            <BulletPointText>Key Information at Glance </BulletPointText>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src="/weather.svg" />
            <BulletPointText>Event and Weather Updates </BulletPointText>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src="/call-missed.svg" />
            <BulletPointText>Reduced Communication Overhead </BulletPointText>
          </BulletPoint>
        </BulletPoints>
        <BulletPointPromoText>
          Effortlessly enhance your guest experience with personalized,
          informative welcome screens.
        </BulletPointPromoText>
      </ImageHalf>
    </SignupWrapper>
  );
}

const SignupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
`;
const FormHalf = styled.div`
  flex: 0 0 51%;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const FormHeader = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    height: 40px;
    width: 100%;
    object-fit: contain;
  }

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
`;

const SigninText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  text-align: left;
  font-optical-sizing: auto;
  margin: 0;
  color: #5c5c5c;
`;

const SigninLink = styled.a`
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  appearance: none;
  color: #5c5c5c;
`;
const ImageHalf = styled.div`
  flex: 0 0 49%;
  background-image: url("/signup-bg.jpeg");
  background-size: cover;
  background-color: #000000b8;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BulletPoints = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 25%;
`;

const BulletPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BulletPointIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const BulletPointText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: white;
  margin: 0 0 0 40px;
`;

const BulletPointPromoText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: white;
  text-align: left;
  margin-top: 176px;
  margin-left: 25%;
  width: 50%;
`;

export default VerifyEmail;
