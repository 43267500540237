import React from "react";
import Row from "../components/Row";
import styled from "@emotion/styled";

type EmailSentProps = {
  email: string;
};

function EmailSent({ email }: EmailSentProps) {
  return (
    <FormContainer>
      <Form>
        <Row>
          <FormTitle>Thank you!</FormTitle>
        </Row>
        <Row>
          <Description>
            If a matching account was found, an email has been sent to
            {email} with instructions to reset your password.
          </Description>
        </Row>
        <Row>
          <HelperSection>
            <HelperTitle>Can’t find the email?</HelperTitle>
            <HelperList>
              <HelperItem>Verify the provided email address</HelperItem>
              <HelperItem>Check your spam inbox</HelperItem>
              <HelperItem>
                Send us a request to{" "}
                <HelperLink href="mailto:support@zonesage.com" target="_blank">
                  support@zonesage.com
                </HelperLink>
              </HelperItem>
            </HelperList>
          </HelperSection>
        </Row>
      </Form>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const FormTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 100%;
  justify-content: center;
  gap: 20px;
`;

const Description = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin: 0;
  color: #000000;
`;

const HelperSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const HelperTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #585858;
  margin: 0 0 13px 0;
`;

const HelperList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const HelperItem = styled.li`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #585858;
`;

const HelperLink = styled.a`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #585858;
  text-decoration: underline;
  text-underline-offset: 2px;
  margin: 0;
  padding: 0;
`;

export default EmailSent;
