import React from "react";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  onClick: () => void;
};
export const AddButton = ({ onClick }: Props) => {
  return (
    <PropertyWrap onClick={onClick}>
      <AddCircle>
        <AddIcon
          sx={{
            color: "#A8A6A6",
          }}
          fontSize="large"
        />
      </AddCircle>
    </PropertyWrap>
  );
};

const PropertyWrap = styled.button`
  width: auto;
  border: 1px dashed #000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  min-height: 226px;

  &:hover {
    cursor: pointer;
  }
`;

const AddCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  border: 1px solid #a8a6a6;
`;
