import type { PropsWithChildren } from "react";
import * as React from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";

type Props = {
  onClose: () => void;
  // children: ReactNode
  open: boolean;
  modalTitle?: string;
};

export const BasicModal = ({
  children,
  onClose,
  open,
  modalTitle,
}: PropsWithChildren<Props>) => (
  <ModalStyled
    onClick={(e) => e.stopPropagation()}
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <BoxStyled>
      {modalTitle && <ModalTitle>{modalTitle}</ModalTitle>}
      {children}
    </BoxStyled>
  </ModalStyled>
);

const ModalTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #000;
  text-align: center;
`;

const ModalStyled = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;

const BoxStyled = styled(Box)`
  background: #fff;
  width: auto;
  height: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  border: none;
  border-radius: 6px;
  overflow: hidden;

  &:focus {
    outline: none;
  }
`;
