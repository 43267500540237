import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Link from "@mui/material/Link";
import { matchRoutes, useLocation } from "react-router-dom";

export const TopMenu = () => {
  const location = useLocation();
  const [title, setTitle] = useState("Welcome Screen");

  const routes = [
    { path: "/", title: "Properties List" },
    { path: "/properties", title: "Properties List" },
    { path: "/properties/:id/edit", title: "Edit Property" },
    { path: "/properties/:id/guests", title: "Edit Guests" },
  ];

  useEffect(() => {
    const matchedRoutes = matchRoutes(routes, location.pathname);
    const currentRoute = matchedRoutes?.[0]?.route;

    const pageTitle = currentRoute ? currentRoute.title : "Welcome Screen";
    setTitle(pageTitle);
  }, [location]);

  return (
    <TopMenuWrap>
      <Title>{title}</Title>
      <IconWrap>
        <NotificationsNoneOutlinedIcon
          fontSize="small"
          sx={{ color: "#000" }}
        />
      </IconWrap>
    </TopMenuWrap>
  );
};

const TopMenuWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: white;
  padding: 0 20px;
  -webkit-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 1;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 22px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
`;

const IconWrap = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e2e8f0;
  width: 34px;
  height: 34px;
  border-radius: 40px;

  &:hover {
    cursor: pointer;
  }
`;
