import * as React from "react";

const ImageIcon = ({ stroke }: { stroke: string }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50px"
        height="50px"
        fill="none"
    >
        <path
            stroke={stroke || "#000"}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.643 48.357h40.071a3.643 3.643 0 0 0 3.643-3.643V4.643A3.643 3.643 0 0 0 44.714 1H4.643A3.643 3.643 0 0 0 1 4.643v40.071a3.643 3.643 0 0 0 3.643 3.643Z"
        />
        <path
            stroke={stroke || "#000"}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m5.554 48.357 26.592-25.063a1.822 1.822 0 0 1 2.332 0l13.88 9.945M15.572 21.036a5.464 5.464 0 1 0 0-10.929 5.464 5.464 0 0 0 0 10.929Z"
        />
    </svg>
);
export default ImageIcon;
