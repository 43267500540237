import { useEffect, useState } from "react"

type WindowDimensions = {
    width: number | undefined
    height: number | undefined
}

export const useWindowDimensions = (): WindowDimensions => {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
        width: undefined,
        height: undefined,
    })

    function handleResize(): void {
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
        return (): void => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
}
