import styled from "@emotion/styled";
import { FC } from "react";

type Props = {
  qrCodeUrl: string;
  useWhiteFont?: boolean;
};

export const WelcomeScreenQrCode: FC<Props> = ({ qrCodeUrl, useWhiteFont }) => {
  return (
    <Wrapper useWhiteFont={useWhiteFont}>
      {qrCodeUrl && (
        <div>
          <StyledImage alt="qr-code" src={qrCodeUrl} />
          <QRText>Guide Book</QRText>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ useWhiteFont?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  color: ${({ useWhiteFont }) => (useWhiteFont ? "#fff" : "#000")};
`;

const StyledImage = styled.img`
  margin: 15px;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);

  /* Default size */
  width: 50px;
  height: 50px;

  /* For HD (1280x720) TVs */
  @media (min-width: 1281px) and (max-width: 1919px) {
    width: 75px; /* Proportional size for HD TVs */
    height: 75px;
  }

  /* For Full HD (1920x1080) TVs */
  @media (min-width: 1920px) and (max-width: 3839px) {
    width: 100px;
    height: 100px;
  }

  /* For 4K Ultra HD (3840x2160) TVs */
  @media (min-width: 3840px) and (max-width: 7679px) {
    width: 250px; /* Proportional size for 4K TVs */
    height: 250px;
  }

  /* For 8K Ultra HD (7680x4320) TVs */
  @media (min-width: 7680px) {
    width: 400px; /* Proportional size for 8K TVs */
    height: 400px;
  }
`;

const QRText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: center;
  margin: 0;
  /* For HD (1280x720) TVs */
  @media (min-width: 1281px) and (max-width: 1919px) {
    font-size: 18px;
    line-height: 29px;
  }

  /* For Full HD (1920x1080) TVs */
  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 24px;
    line-height: 29px;
  }

  /* For 4K Ultra HD (3840x2160) TVs */
  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 48px;
    line-height: 77.44px;
  }

  /* For 8K Ultra HD (7680x4320) TVs */
  @media (min-width: 7680px) {
    font-size: 64px;
    line-height: 77.44px;
  }
`;
