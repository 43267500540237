import React from "react";
import styled from "@emotion/styled";

type WeatherBlockType = {
  value: string;
  onChange: (str: string) => void;
};

function WeatherBlock({ value, onChange }: WeatherBlockType) {
  return (
    <div>
      <Title>Weather</Title>
      <Row>
        <TextFieldStyled
          placeholder="Location"
          value={value || ""}
          disabled
          onChange={(e) => onChange(e.target.value)}
        />
      </Row>
    </div>
  );
}

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
`;

const TextFieldStyled = styled.input`
  width: 100%;
  border-radius: 10px;
  color: black;
  border: 1px solid #a8a6a6;
  padding: 2px 10px;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  margin-bottom: 15px;

  &::placeholder {
    color: #a8a6a6;
  }
`;

export default WeatherBlock;
