import React from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ForgotPasswordForm from "./ForgotPasswordForm";
import EmailSent from "./EmailSent";
import axios from "axios";

const validationForgotPasswordSchema = yup.object({
  email: yup.string().email().required("Email is required"),
});

function Index() {
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = React.useState(false);

  const { values, errors, setFieldError, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validateOnChange: false,
      validationSchema: validationForgotPasswordSchema,
      onSubmit: () => sendEmail(),
    });

  const goBack = () => {
    navigate(-1);
  };

  const sendEmail = () => {
    axios
      .post("/api/auth/forgot-password", {
        email: values.email,
      })
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setFieldError("email", "Invalid email");
        } else if (error.response) {
          setFieldError("email", "Internal server error, try again later");
        }
      });
  };

  return (
    <ForgotPasswordWrapper>
      <FormWrapper>
        <FormHeader>
          <Logo>
            <img src="/logo-wide.png" alt="Logo" />
          </Logo>
          <SignupText>
            Don’t have an account?{" "}
            <SignupLink href="/signup">Sign up </SignupLink>
          </SignupText>
        </FormHeader>
        {emailSent ? (
          <EmailSent email={values.email} />
        ) : (
          <ForgotPasswordForm
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            goBack={goBack}
          />
        )}
      </FormWrapper>
    </ForgotPasswordWrapper>
  );
}

const ForgotPasswordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
  width: 100%;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const FormHeader = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    height: 40px;
    width: 100%;
    object-fit: contain;
  }

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
`;

const SignupText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  text-align: left;
  font-optical-sizing: auto;
  margin: 0;
  color: #5c5c5c;
`;

const SignupLink = styled.a`
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  appearance: none;
  color: #5c5c5c;
`;

export default Index;
