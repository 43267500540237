import { FC } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";

type ContactsSectionProps = {
  contact_phone: string | undefined;
  contact_email: string | undefined;
};

export const ContactsSection: FC<ContactsSectionProps> = ({
  contact_phone,
  contact_email,
}) => (
  <Wrapper>
    <Title>Contacts</Title>

    <InfoText>
      Phone: <span>{contact_phone || "Phone number not available"}</span>
    </InfoText>

    <InfoText>
      Email: <span>{contact_email || "Email not provided"}</span>
    </InfoText>
  </Wrapper>
);

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
`;

const Title = styled.p`
  font-size: 15px;
  line-height: 19px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin: 0 0 5px;

  @media (min-width: 1280px) and (max-width: 1919px) {
    font-size: 19px;
    line-height: 23px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 22px;
    line-height: 27px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 29px;
    line-height: 35px;
  }

  @media (min-width: 7680px) {
    font-size: 44px;
    line-height: 54px;
  }
`;

const InfoText = styled.p`
  color: #1982fc;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  margin: 0;
  text-align: center;

  & span {
    font-weight: 500;
  }

  @media (min-width: 1280px) and (max-width: 1919px) {
    font-size: 15px;
  }

  @media (min-width: 1920px) and (max-width: 3839px) {
    font-size: 18px;
  }

  @media (min-width: 3840px) and (max-width: 7679px) {
    font-size: 24px;
  }

  @media (min-width: 7680px) {
    font-size: 36px;
  }
`;
