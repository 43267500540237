import moment from "moment";

export const formatDateAndTime = (datetime: string) => {
    const date = moment(datetime);

    const formattedDate = date.format("ddd M/D");
    const formattedTime = date.format("hA").toLowerCase();

    return { date: formattedDate, time: formattedTime };
};
