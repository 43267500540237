import React from "react";
import styled from "@emotion/styled";

type FontColorSwitchProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

function FontColorSwitch({ value, onChange }: FontColorSwitchProps) {
  return (
    <Wrapper>
      <Row>
        <Title>Header Color</Title>
        <Switch onClick={() => onChange(!value)}>
          <Value aria-label="light">
            <img src="/day.svg" alt="Light" />
          </Value>
          <Value aria-label="dark">
            <img src="/night.svg" alt="Dark" />
          </Value>
          <Toggle value={value} />
        </Switch>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  //box-sizing: border-box;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  margin-right: auto;
  //margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;

const Switch = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px;
  gap: 10px;
  background: #f5f7fa;
  border-radius: 10px;
  border: 0.5px solid #e7e7e7;
`;

const Value = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 5px 10px;
  z-index: 1;
  cursor: pointer;

  image {
    display: block;
    width: 20px;
    height: 20px;
  }
`;

const Toggle = styled.div<{ value: boolean }>`
  position: absolute;
  width: calc(50% - 10px);
  height: calc(100% - 10px);
  background-color: white;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 0.3s;
  top: 5px;
  left: ${({ value }) => (!value ? "calc(50% + 5px)" : "5px")};
  z-index: 0;
  box-shadow: 0px 1px 3px 0px #00000024;
`;

const Text = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-right: auto;
  //color: #a8a6a6;
`;

export default FontColorSwitch;
