import * as React from "react";
import {FC} from "react";

interface PlayIcon {
    width: number;
    height: number;
    fill?: string;
}

const PlayIcon: FC<PlayIcon> = ({
    width,
    height,
    fill = "#D9D9D9"
    }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 55 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"

    >
        <path d="M55 32.5L0.25 64.1099L0.25 0.890072L55 32.5Z" fill={fill} />
    </svg>
);

export default PlayIcon;
